import Button from "../../atoms/button"
import heroImg from '../../../assets/img/bg/slide_1.jpg'
import heroImgMobile from '../../../assets/img/bg/slide_1_mobile.png'
import { Link } from "react-router-dom"
import { isMobile } from "react-device-detect"

export default function Slide1(){	
	return(
			<div className="hero__1 bg-dark mb-5 mt-5" style={{background: `url(${ isMobile ? heroImgMobile : heroImg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '85vh'}}>
					<div className="hero-inner h-100 my-auto mx-auto">
						<div className="row align-items-center h-100 my-auto pl-5">
								<div className="d-flex flex-column justify-content-center space-y-20 h-100 my-auto">
									<h1 className="hero__title text-primary" style={{fontSize: '48px'}}>
										Spatial Spawns Service
									</h1>
									<p className="hero__text txt text-light" style={{fontSize: '25px', maxWidth: '80vw'}}>
										Your hyper realistic <span className="text-primary">NFT holograms </span><br></br>by inheritance Art on a Sony Spatial Reality Display
									</p>
									<div className="d-flex flex-column flex-md-row">
										<Link to="/sss">
											<Button classNames="btn btn-primary mb-3 px-4">Learn More</Button>
										</Link>
									</div>
								</div>
						</div>
			
					</div>
			</div>
	)
}