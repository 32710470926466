import React from 'react'
import Tooltip from '../atoms/tooltip'

function StepCard({title, text, status, light, statusLabel, statusValue, tooltipTitle, tooltipText, ctaButtons, }) {
	return (
			<div className="col-12 col-md-4 mb-3">
				<div className="card rounded bg-dark">
					<div className={`card-header d-flex justify-content-between p-3 bg-dark status-header border-${status ? 'success' : light ? 'light' : 'danger' }`}>
						<h5 className="">{title}</h5>
						<Tooltip title={tooltipTitle} text={tooltipText} />
					</div>

					<div className="card-body">
						<h6 className="card_title">{text}</h6>

						<div className="card_footer d-block
								space-y-10">
							<div className="d-flex justify-content-between align-items-center">
								<div className="creators space-x-10">
									<div>
										<span>{statusLabel}</span>
									</div>
								</div>
								<div className="space-x-3">
									<p className={`color_${status ? 'green' : 'red'} txt_sm`}>{statusValue}</p>
								</div>
							</div>
							<div className="hr">
							</div>
							<div className="dropdown d-flex justify-content-end">
								{ctaButtons.map((b) => {
									return b
								})}
								{/*{ctaButton && ctaButton}*/}
							</div>
						</div>

					</div>

				</div>

			</div>
	)
}

export default StepCard
