import React from 'react'
import avatartImg from '../../assets/img/items/item_1.png'

function ActivityListItem() {
  return (
	  <div className="card box mb-3">
		<div className="row justify-content-between align-items-center">
			<div className="col-auto">
				<div className="creators_box">
					<div className="avatars space-x-10">
						<div className="media">
							<img src={avatartImg} alt="Avatar" className="avatar avatar-md" />
						</div>
						<div>

							<h5 className="">Creative Art collection</h5>
							<div className="d-flex space-x-10">
								<span className="price color_text">listed
									by
								</span>
								<a href="Profile.html" className="price color_brand">@inheritance_art</a>
							</div>
							<p className="date color_text d-block d-md-none"> Last 2 days </p>
						</div>
					</div>
				</div>
			</div>
			<div className="col-auto d-none d-md-block">
				<p className="date color_text"> Last 2 days </p>
			</div>
		</div>

	</div>
  )
}

export default ActivityListItem