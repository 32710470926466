import React from 'react'

function Accordion({children, id, classNames}) {
  return (
	<div className={`accordion px-0 ${classNames?classNames:''}`} id={id} style={{border: 'none'}}>
		{children}
	</div>
  )
}

export default Accordion