import addresses from "../config/addresses";
import contract from "../hooks/use-contract";
import {utils} from "ethers";
import ERC20ABI from '../abis/erc20.json';
import ERC721ABI from '../abis/Inheritance721_ABI.json';
import ERC1155ABI from '../abis/Inheritance1155_ABI.json';
import AuctionHouseABI from '../abis/AuctionHouse_ABI.json';
import {Contract} from "@ethersproject/contracts";
import web3 from "web3";

const {useCall, useContractFunction} = require("@usedapp/core");

const erc20Interface = new utils.Interface(ERC20ABI);
const iaiInterface = new utils.Interface(ERC20ABI);
const erc721Interface = new utils.Interface(ERC721ABI);
const erc1155Interface = new utils.Interface(ERC1155ABI);
const auctionHouseInterface = new utils.Interface(AuctionHouseABI);

const auctionHouse = addresses.auctionHouse;

const auctionHouseContract = new Contract(addresses.auctionHouse, auctionHouseInterface)


const UseContractMethod = (contractObj, methodName) => {
    const {state, send, events} = useContractFunction(contractObj, methodName, {});
    return {state, send, events};
};

const IsCollectionApproved = (collectionContract, owner) => {
    const approved = useCall({
        contract: collectionContract,
        method: "isApprovedForAll",
        args: [owner, addresses.auctionHouse]
    });

    return approved? approved.value[0]: null;
}

const IsTokenApproved = (collectionContract, tokenId) => {
    const approvedOperators = useCall({
        contract: collectionContract,
        method: "getApproved",
        args: [tokenId]
    });

    return approvedOperators ? approvedOperators[0] == addresses.auctionHouse : null;
}

const GetNFTSeller = (collectionContract, tokenId) => {
    const sellerInfo = useCall({
        contract: collectionContract,
        method: "getArtist",
        args: [tokenId]
    });

    return sellerInfo && sellerInfo.value ? sellerInfo.value[0] : null;
}

const GetListingInfo = (listingID) => {
    const listing = useCall({
        contract: auctionHouseContract,
        method: "getListingInfo",
        args: [listingID],
    });

    let listingDict = {}

    if(listing){
        const li = listing.value.listing;
        listingDict = {
            list_id: listingID,
            duration: li.duration.toNumber(),
            endTime: li.endTime.toNumber(),
            highestBidder: li.highestBidder != "0x0000000000000000000000000000000000000000" ? li.highestBidder : null,
            in_iAI: li.in_iAI,
            isActive: li.isActive,
            isExclusive: li.isExclusive,
            listingAdmin: li.listingAdmin,
            nftAddress: li.nftAddress,
            numberOfTokens: li.numberOfTokens.toNumber(),
            price: web3.utils.fromWei(li.price.toString()),
            rawPrice: li.price,
            seller: li.seller,
            tokenID: li.tokenID.toNumber(),
            tokenOwner: li.tokenOwner,
            whitelistCount: li.whitelistCount.toNumber()
        }
    }

    return listingDict
}

const IsAdmin = (wallet) => {
    const isAdmin = useCall({
        contract: auctionHouseContract,
        method: "isAdmin",
        args: [wallet],
    });

    console.log(isAdmin);

    return isAdmin ? isAdmin.value[0] : false;
}

const BalanceOf = (tokenContract, account) => {

    const balance = useCall({
        contract: tokenContract,
        method: "balanceOf",
        args: [account],
    });

    return balance && balance.value ? balance.value[0].toBigInt().toString() : "0";
};

const MinStakeAmount = () => {

    const stakeAmount = useCall({
        contract: auctionHouseContract,
        method: "minimumStakeAmount",
        args: [],
    });

    return stakeAmount && stakeAmount.value ? stakeAmount.value[0].toBigInt().toString() : "0";
};
const AccountStakedAmount = (account) => {

    const accountStakedAmount = useCall({
        contract: auctionHouseContract,
        method: "staked",
        args: [account],
    });

    return accountStakedAmount && accountStakedAmount.value ? accountStakedAmount.value[0].toBigInt().toString() : "0";
};

const AccountStakedTime = (account) => {

    const accountStakedTime = useCall({
        contract: auctionHouseContract,
        method: "stakeTime",
        args: [account],
    });

    return accountStakedTime && accountStakedTime.value ? new Date(accountStakedTime.value[0]*1000) : null;
};

const LockTime = () => {

    const lockTime = useCall({
        contract: auctionHouseContract,
        method: "lockTime",
        args: [],
    });

    return lockTime && lockTime.value ? lockTime.value[0].toNumber() : null;
};

export {
    UseContractMethod,
    GetListingInfo,
    BalanceOf,
    IsAdmin,
    IsCollectionApproved,
    IsTokenApproved,
    GetNFTSeller,
    AccountStakedAmount,
    MinStakeAmount,
    AccountStakedTime,
    LockTime
}