import React from "react";
import {Link} from "react-router-dom";
import {useSystem} from "../../hooks/use-system";
import Button from "../atoms/button";
import CopyBtn from "../atoms/copy-btn";
import {GetListingInfo} from "../../hooks/use-dapp";

function ItemCard({
                      collection,
                      tokenID,
                      listingID,
                      card,
                      listed,
                      isAdmin,
                      classNames,
                        ownerWallet
                  }) {
    const system = useSystem();

    const nftLink = `/auction-house/collection/${collection.address}/${tokenID}`
    const listingInfo = listed ? GetListingInfo(listingID) : null;

    return (
        <div className={`${classNames ? classNames : 'col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-3'}`}>
            <div className="card card__item four bg-dark">
                <div className="card_body space-y-10">
                    <div className="creators space-x-10">
                        <div className="avatars space-x-3 w-100">
                            <img
                                src={
                                    "https://dapps.blacklemon.wtf/assets/img/logos/iai_logo_black.jpeg"
                                }
                                alt="Avatar"
                                className="avatar avatar-sm mr-2"
                            />
                            <Link to={nftLink}><p
                                className="text-light">{collection.name}</p></Link>
                        </div>
                        <i className="ri-heart-fill color-red"></i>
                    </div>
                    <div className="card_head">
                        <a href="#"><img src={card.img} alt="item img"/></a>
                        <a href="#" className="likes space-x-3">
              <span className="txt_sm">

              </span>
                        </a>
                    </div>

                    <div className="hr mt-3"></div>

                    <div className="d-flex justify-content-between mb-3">
                        <div>
                            <i className="ri-quill-pen-line"></i><span>{collection.category}</span>
                        </div>
                        <div className="d-flex align-items-center border-top-1">
                            <h6 className="color_text text-nowrap mr-2">Token ID </h6>
                            <p className="d-flex">{tokenID}</p>
                        </div>
                    </div>
                    {
                        isAdmin &&
                        <>
                            <h6 className="card_title">
                                {/* <div style={{visibility: listed ? 'visible': 'hidden'}}> */}
                                <div>
                                    <div className="d-flex justify-content-between">
                                        <h6>Bid Price:</h6>
                                        <h6>{listed ? `${listingInfo?.price} ` + (listingInfo?.in_iAI ? 'iAI' : 'WETH') : 'N/A'}</h6>
                                    </div>
                                </div>
                            </h6>
                            <div className="card_footer d-block space-y-10">
                                <div className="hr"></div>
                                <div className="d-flex align-items-center space-x-10 justify-content-between">
                                    {/*{!listed && <Button classNames="btn btn-sm btn-outline-danger" onClick={() => {*/}
                                    {/*    system.showModal("burn", card);*/}
                                    {/*}}>Burn</Button>}*/}
                                    {!listed && <Button classNames="btn btn-sm btn-primary" onClick={() => {
                                        system.showModal("put-on-auction", {collection: collection, tokenId: tokenID, ownerWallet: ownerWallet});
                                    }}>Start Auction</Button>}
                                    {listed && listingInfo.endTime === 0 && <Button classNames="btn btn-sm btn-outline-primary" onClick={() => {
                                        system.showModal("cancel-auction", {listingInfo: listingInfo});
                                    }}>Cancel Auction</Button>}
                                </div>
                            </div>
                        </>
                    }
                    {
                        !isAdmin &&
                        <div className="d-flex justify-content-between">
                            <Link to={`/auction-house/collection/${collection.address}/${tokenID}`}
                                  className="btn btn-sm btn-outline-primary">View</Link>
                            <div className="my-auto">
                                {/*<i className="ri-gift-line ml-2 border rounded-circle p-1 icon-small hover my-auto"></i>*/}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ItemCard;
