import Button from "../../atoms/button"
import heroImg from '../../../assets/img/bg/slide_3.png'
import heroImgMobile from '../../../assets/img/bg/slide_3_mobile.png'
import { isMobile } from "react-device-detect"
import { Link } from "react-router-dom"

export default function Slide1(){	
	return(
			<div className="hero__1 bg-dark mb-5 mt-5" style={{background: `url(${ isMobile ? heroImgMobile : heroImg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '85vh'}}>
					<div className="hero-inner h-100 my-auto">
						<div className="row align-items-center h-100 my-auto">
								<div className="d-flex flex-column justify-content-center space-y-20 h-100 my-auto col-12 col-md-6">
									<h1 className="hero__title text-center text-light mx-auto mb-3" style={{fontSize: '52px'}}>
										iAI SEED<br></br><span className="text-primary">NFT Staking</span>
									</h1>
									<p className="text-center txt text-light mx-auto mb-3" style={{fontSize: '25px', maxWidth: '450px'}}>
										stake your iAI seed NFTs to mint iAI tokens
									</p>
									<div className="d-flex flex-column mx-auto">
										<a href="https://dapps.inheritance-art.com/nft-staking.html" target={"_blank"}>
										<Button classNames="btn btn-lg btn-primary px-5">Explore</Button>
										</a>
									</div>
								</div>
						</div>
			
					</div>
			</div>
	)
}