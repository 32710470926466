import React from 'react'

function PageTitle({text1, text2, aboveTitle, classNames}) {
  return (
	<div className={`py-5 page-title ${classNames ? classNames : ''}`}>
		<div className="container my-3">
			<h5 className="text-center text-primary">{aboveTitle}</h5>
			<h1 className="text-center">{text1}</h1>
			<h1 className="text-center text-primary">{text2}</h1>
		</div>
	</div>
  )
}

export default PageTitle