import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {prettyNumber, shorter} from '../../utils/string';
import CopyBtn from '../atoms/copy-btn';

import LogoiAI from "../../assets/img/logos/iai_logo.png"
import logoUSDC from "../../assets/img/logos/usdc-logo.png";
import logoWETH from "../../assets/img/logos/weth.png";
import {BalanceOf} from "../../hooks/use-dapp";
import {useContract} from "../../hooks/use-contract";
import web3 from "web3";
import config from "../../config";

function WalletMenu({account, deactivate}) {

	const contract = useContract();
	const [visible, setVisible] = useState(false);

	const iaiBalance = web3.utils.fromWei(BalanceOf(contract?.iai, account));
	const usdcBalance =  web3.utils.fromWei(BalanceOf(contract?.usdc, account));
	const wethBalance =  web3.utils.fromWei(BalanceOf(contract?.weth, account));

	const toggleVisible = () => {
		setVisible(!visible);
	};

	return (
		<div className="d-flex">
			<div className="header__avatar" onClick={toggleVisible}>
				<div className="price">
					<span>
						{prettyNumber(iaiBalance)} <strong>iAI</strong>{' '}
					</span>
				</div>
				<div className="avatar d-flex align-items-center">
					<i className="ri-wallet-3-line"></i>
				</div>
				<div className={`avatar_popup space-y-20 ${visible ? 'visible' : ''}`}>
					<div className="d-flex align-items-center justify-content-between">
						<span> {shorter(account)} </span>
						<CopyBtn copyText={account}/>
					</div>
					<div className="d-flex align-items-center space-x-10">
						<img
							className="coin"
							src={LogoiAI}
							width={35}
							alt="/"
						/>
						<div className="info">
							<p className="w-full text-sm font-bold text-green-500">
								{prettyNumber(iaiBalance)} iAI
							</p>
						</div>
					</div>
					<div className="d-flex align-items-center space-x-10">
						<img className="coin" src={logoWETH} alt="/" width={35} />
						<div className="info">
							<p className="w-full text-sm font-bold text-green-500">
								{prettyNumber(wethBalance)} WETH
							</p>
						</div>
					</div>
					<div className="d-flex align-items-center space-x-10">
						<img className="coin" src={logoUSDC} alt="/" width={35} />
						<div className="info">
							<p className="w-full text-sm font-bold text-green-500">
								{prettyNumber(usdcBalance)} USDC
							</p>
						</div>
					</div>

					<div className="hr"></div>
					<div className="links space-y-10">
							{/*<a href="profile" className="text-light"><div className="d-flex"><i className="ri-landscape-line mr-2"></i><span className="text-light">My items</span></div></a>*/}
							{/*<a href="edit-profile" className="text-light"><div className="d-flex"><i className="ri-pencil-line mr-2"></i><span className="text-light">Edit Profile</span></div></a>*/}
						<a onClick={() => {
							localStorage.removeItem(config.constants.STORAGE.AUTH_TOKEN);
							localStorage.removeItem(config.constants.STORAGE.CONNECTOR);
							deactivate()}} className="text-light">
							<i className="ri-logout-circle-line"></i> <span>Disconnect</span>
						</a>
					</div>
				</div>
			</div>
			<div className="avatar d-flex align-items-center ml-2">
				<Link to="/profile">
					<i className="ri-user-3-fill border rounded-circle p-1"></i> <span className={'d-none d-sm-inline'}>Profile</span>
				</Link>
			</div>
		</div>
	);
}

export default WalletMenu;
