import React from 'react'

function ProfileCover({imgUrl}){
	return (
		<div className="cover">
			<img src={imgUrl} id="profileBanner" alt="" />
		</div>
	)
}

export default ProfileCover