import React from 'react'

function PageBanner(img) {
	
	return (
			<div className="hero__banner">
				<div className="cover" style={{background: 'linear-gradient(180deg, #2AE3FD 24.12%, #FC2934 100%)'}}>
					{img && <img src={img.img}/>}
				</div>
			</div>
	)
}

export default PageBanner