import * as React from 'react';

function SvgProgress(props) {
  return (
      <svg width="50" height="50" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
          <path d="M42 22.7998L28.1538 35.9998L24 32.0438" stroke="#1B1B1B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <circle cx="30.0016" cy="30.0001" r="25.9" stroke="#3D3D3D"/>
          <path d="M55.2031 29.9999C55.2031 44.5802 43.3834 56.3999 28.8031 56.3999" stroke="#2AE3FD" stroke-width="3" stroke-linecap="round"/>
      </svg>
  );
}

export default SvgProgress;
