import React from "react";
import CountdownTimer from "./countdown";
import {prettyNumber} from "../../utils/string";

function ItemsDetailNumbers({
                                listingInfo, auctionEnded
                            }) {
    const bidAlready = listingInfo != {} && listingInfo.highestBidder !== null;
    return (
        <div className="numbers">
            <div className="row">
                <div className="col-8">
                    <div className="space-y-5">
                        <p className="color_text">Countdown</p>
                        <div className="d-flex countdown_item align-items-center">
                            {listingInfo.highestBidder === null ? (
                                <p style={{lineHeight: '100%'}}><small><small><small>Will start once 1st bid is
                                    placed</small></small></small></p>
                            ) : (
                                <CountdownTimer date={new Date(listingInfo.endTime * 1000)}/>
                            )}

                        </div>

                    </div>
                </div>
                <div className="col-4">
                    <div className="space-y-5">

                        {listingInfo?.price &&
                            <><p
                                className="color_text">  {auctionEnded ? 'Sold At' : bidAlready ? 'Current Bid' : 'Minimum Bid'}</p>
                                <h3 className={'txt_lg bid_price'}>
                                    {prettyNumber(listingInfo?.price)}
                                    <span className="txt_sm color_text"> {listingInfo?.in_iAI ? 'iAI' : 'WETH'}</span>
                                </h3>
                            </>
                        }
                    </div>
                </div>
                {(listingInfo?.price && !listingInfo.in_iAI) &&
                    <div className={'col-12 mt-2'}>
                        <p>
                            <small><i
                                className={'ri-information-fill'}></i><small>Every ETH auction is done in Wrapped ETH.
                                You can swap your ETH for wETH
                                using <a
                                    href={'https://app.uniswap.org/#/swap'} target={'_blank'}>Uniswap <i
                                    className={'ri-external-link-line'}></i> </a></small></small>.
                        </p>
                    </div>
                }
            </div>
        </div>
    );
}

export default ItemsDetailNumbers;
