function SectionHeader({text, backdrop, justify}) {

	let backdropPosition = 'left'
	if(justify&&justify === 'end'){
		backdropPosition = 'right';
	}else if(justify&&justify === 'center'){
		backdropPosition = 'center';
	}

	return (
		<div className="section__head mb-5">
			<div className={`d-flex justify-content-${justify?justify:'between'} align-items-center`} style={{position: 'relative'}}>
				{backdrop && <h1 className={`title-backdrop ${backdropPosition}`}>{backdrop}</h1>}
				<h1 className="section__title">{text}</h1>
			</div>
		</div>
	)
}

export default SectionHeader
