import React, { useState } from 'react'

function Dropdown({text, icon, items, classNames}) {
	const [show, setShow] = useState(false)
	return (
		// <div className="dropdown d-flex justify-content-end">
		<div className={`dropdown ${show && 'show'} ${classNames}`}>
			<button className="btn btn-primary btn-sm dropdown-toggle w-auto" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={()=>setShow(!show)}>
				{icon}
				{text}
			</button>
			<div className={`dropdown-menu ${show && 'show'}`}>
				{
					items.map((item, index)=>{
						return item.text != text ? <a className="dropdown-item" href={item.url ? item.url : null} onClick={ (e) => {
							item.onClick();
							setShow(false);
						}} target="_blank" rel="noreferrer" key={index}>
								<span>{item.text}</span>
								{/* <i className="ri-external-link-line color_brand"></i> */}
							</a> : null;
					})
				}
			</div>
		</div>
	)
}

export default Dropdown