import React, {useEffect, useState} from 'react'
import Button from '../../atoms/button'
import Input from '../../atoms/input'
import Modal from '../../atoms/modal'
import InputRow from '../../molecules/input-row'
import {UseContractMethod} from "../../../hooks/use-dapp";
import {useContract} from "../../../hooks/use-contract";
import callAPI from "../../../api";

function CancelAuctionModal({listingInfo}) {

    const contract = useContract();

    const [cancelled, setCancelled] = useState(false);
    const [message, setMessage] = useState("");

    const {state, send, events} = UseContractMethod(contract?.auctionHouse, "cancelListing");

    useEffect(() => {
        console.log(state)
        if (state.status === 'Exception') {
            setMessage(null);
        }
        else if (state.status === 'Mining') {
            setMessage('Cancelling your auction...')
        }
        else if (state.status === 'Success') {
            setCancelled(true);
            callAPI({list_id: listingInfo.list_id}, 'cancelListing')
        }
    }, [state])

    return (
        <Modal title="Cancel auction" classNames="bg-dark" containerClassNames="staking-modal">
            {cancelled ? (
                <p>
                    The auction has been cancelled successfully!
                </p>
            ) : (
                <>
                    <p className="text-muted">Are you sure you want to cancel this auction?</p>
                    <div className="d-flex justify-content-center">
                        {message ? message : (
                            <>
                                <Button classNames="btn btn-primary btn-sm mr-3" onClick={() => {
                                    send(listingInfo.list_id);
                                    setMessage('Please sign the transaction...');
                                }}>Yes</Button>
                                <Button classNames="btn btn-primary btn-sm">Cancel</Button>
                            </>
                        )}
                    </div>
                </>
            )}


        </Modal>
    )
}

export default CancelAuctionModal