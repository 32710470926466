import React from 'react'
import Accordion from '../atoms/Accordion'
import AccordionItem from '../atoms/AccordionItem'
import PageTitle from '../atoms/pageTitle'
import PageBanner from '../molecules/page-banner'
import discord_icon from '../../assets/img/icons/discord_lg.png'
import InputRow from '../molecules/input-row'
import Button from '../atoms/button'
import Input from '../atoms/input'
import { useState } from 'react'
import callAPI from '../../api'
import {useSystem} from '../../hooks/use-system'

function Support() {
	const [data, setData] = useState({})
	const [loading, setLoading] = useState(false)
	const {setError, setSuccess} = useSystem();
	const handleChange = (e)=>{

		setData({
			...data,
			[e.target.name]: e.target.value

		});
	}
	const handleSubmit = ()=>{
		console.log('submit')
		setLoading(true)
		callAPI(data, 'submitContact').then(data=>{
			if(data.status==='SUCCESS'){
				setSuccess('Message submitted succefully!')
			}else{
				setError('Something went wrong! Please try again later.')
			}
			setLoading(false)
		})
	}

  return (

	  <>
	<PageBanner/>
	<PageTitle text1={"Support"} classNames="bg-dark" />
	<div className="col-10 col-md-4 mx-auto text-center mb-5">
		<p>We're answering all the questions we think you might have. If your question is unanswered in the general FAQ, feel free to reach out to our admins on Discord!</p>
	</div>
	<div className='container'>
		<div className="row d-flex justify-content-center">
			<div className="col-12 col-md-5 mb-5 mb-md-0 h-100">
				<div className="card bg-dark p-3 h-100" style={{border: '1px solid #ffffff', minWidth: '100%'}}>
					<div className="card_body space-y-10">
						<h2 className="text-center mt-5">Discord Support</h2>
						<p className="text-light text-center my-5">Keen on knowing of all new projects and updates first? Want to stay tuned with 24/7 real-time support? Head over to our <a href="" className="text-primary"></a>Discord channel</p>
						<div className="d-flex justify-content-center">
							<img src={discord_icon} className="mx-auto my-5" style={{width: '190px'}}/>
						</div>
						<div className="d-flex justify-content-center my-5">
							<a href="https://discord.com/invite/inheritanceart" className="btn btn-primary btn-lg">Join Discord</a>
						</div>
					</div>
				</div>
			</div>
			<div className="col-12 col-md-5">
				<div className="card card__item four bg-dark p-3" style={{border: '1px solid #ffffff', minWidth: '100%'}}>
					<div className="card_body space-y-10 px-5 pb-2">
						<h2 className="text-center mt-5">E-mail Support</h2>
						<p className="text-light text-center my-5">Let us know your questions, suggestions or concerns by filling out the contact form below</p>
						<InputRow title="Name" classNames="px-4">
							<div className="d-flex">
								<Input placeholder="Name" name="name" value={data.name} onChange={handleChange}/>
							</div>
						</InputRow>
						<InputRow title="E-mail" classNames="px-4">
							<div className="d-flex">
								<Input placeholder="E-mail" name="email" value={data.email} onChange={handleChange}/>
							</div>
						</InputRow>
						<InputRow title="Message" classNames="px-4">
							<div className="d-flex">
								<textarea name="message" onChange={handleChange}>{data.message}</textarea>
							</div>
						</InputRow>
						<div className="d-flex justify-content-center my-5">
							<Button classNames="btn btn-primary btn-lg" loading={loading} onClick={handleSubmit}>Submit</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</>
  )
}

export default Support