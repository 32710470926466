import React, {useEffect, useState} from 'react'
import Button from '../../atoms/button'
import Input from '../../atoms/input'
import Modal from '../../atoms/modal'
import InputRow from '../../molecules/input-row'
import Progress from "../../atoms/icons/Progress";
import {useContract} from "../../../hooks/use-contract";
import web3 from "web3";
import Close from "../../atoms/icons/Close";
import callAPI from "../../../api";
import Done from "../../atoms/icons/Done";
import {useEthers} from "@usedapp/core";
import {UseContractMethod} from "../../../hooks/use-dapp";
import config from "../../../config";


function ServiceSubscription({
                                 title,
                                 description,
                                 disclaimer,
                                 btnText,
                                 subscription,
                                 eligible,
                                 subscriber,
                                 payCurrency,
                                 payAmount,
                                 receiverWallet,
                                 successMessage
                             }) {

    const {account, library} = useEthers();
    const contracts = useContract();
    const desiredContract = contracts ? (payCurrency.toUpperCase() === 'USDC' ? contracts.usdc : contracts.iai) : null;


    const {state, send, events} = UseContractMethod(desiredContract, "transfer");

    const [trx, setTrx] = useState(null)

    useEffect(() => {

        if (state.status === 'Mining') {
            setTrx(state.transaction);
            setStage({
                message: `🔐 <a target="_blank" href="https://${config.Ethscan}${state.transaction.hash}"><u>Transaction</u></a> Signed! <br/> Sending your payment...`
            });
        } else if (state.status == 'Success') {
            setStage({
                message: `🔐 <a target="_blank" href="https://${config.Ethscan}${trx.hash}"><u>Transaction</u></a> Confirmed! <br/> Validating your payment...`
            });
            callAPI({
                trx_hash: trx.hash,
                wallet: account,
                subscription_id: subscription,
                email: subscriberEmail,
                nft_link: nftLink
            }, "validateAndCreate")
                .then(function (resp) {
                    if (resp.status == 'SUCCESS') {
                        setStage({
                            success: true,
                            message: `🔐 <a target="_blank" href='https://${config.Ethscan}${trx.hash}'><u>Transaction</u></a> Confirmed! <br/> Congrats! Its validated`
                        });
                    } else {
                        setStage({
                            error: true,
                            message: `Error occured while validating your <a target="_blank" href="https://${config.Ethscan}${trx.hash}"><u>Transaction</u></a>`
                        });
                    }
                })
        }

    }, [state, events])

    const [stage, setStage] = useState(null)

    const [nftLink, setLink] = useState('');
    const [subscriberEmail, setEmail] = useState(subscriber && subscriber.email ? subscriber.email : '');

    return (
        <Modal classNames="bg-dark auction-modal" containerClassNames="staking-modal">
            {
                stage && stage.success ?
                    (
                        <>
                            <div className={'mx-auto my-auto d-flex text-left'}>
                                <Done/>
                                <p className={'text-light d-inline ml-2'}>
                                    <b>{successMessage}</b>
                                </p>
                            </div>
                            <>
                                <p>
                                    You can check the status of approval and to track the progress on your <a
                                    href={'/profile'} style={{
                                    color: '#2AE3FD'
                                }}>Profile</a>
                                </p>
                            </>
                        </>
                    )
                    :
                    (
                        <>
                            <p className="text-light" dangerouslySetInnerHTML={{__html: title}}></p>
                            <hr/>
                            <p className="text-light" dangerouslySetInnerHTML={{__html: description}}></p>
                            {subscriber && subscriber.email ? <p>{subscriber.email}</p> : (
                                <InputRow title="Email" required>
                                    <Input type="email" onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}/>
                                </InputRow>
                            )}
                            <InputRow title="Link to NFT" required>
                                <Input onChange={(e) => {
                                    setLink(e.target.value);
                                }}/>
                            </InputRow>
                            <div className="d-flex justify-content-center mt-2">
                                {stage ? (
                                        <div className={'mx-auto my-auto d-flex text-center'}>
                                            {stage.error ?
                                                <Close/>
                                                :
                                                <Progress className={'rotating'}/>
                                            }
                                            <p className={'text-light d-inline ml-2'}><small
                                                dangerouslySetInnerHTML={{__html: stage.message}}></small></p>
                                        </div>
                                    ) :
                                    <div className={'text-center'}>
                                        <p className={'mb-2'}>
                                            By Clicking the button below you agree to our <a href={"/terms"}
                                                                                             target={'_blank'}>Terms &
                                            Conditions</a>, and <a target={'_blank'} href={'/privacy'}>Privacy
                                            Policy</a>.
                                        </p>

                                        <Button classNames="btn btn-primary btn-lg mr-3" onClick={async () => {

                                            if (nftLink === '' || subscriberEmail === '') {
                                                return alert('Please input missing fields')
                                            }

                                            send(receiverWallet, web3.utils.toWei(payAmount.toString()));
                                            setStage({
                                                message: 'Please sign the payment from your Wallet and wait until confirmed!'
                                            })

                                            // const signer  = library.getSigner();
                                            // const trx = await  signer.sendTransaction({
                                            //     to: receiverWallet,
                                            //     value: web3.utils.toWei(payAmount.toString())
                                            // });
                                            //
                                            // await desiredContract.transfer(receiverWallet,
                                            //     web3.utils.toWei(payAmount.toString())).send({from: account})
                                            //     .then(function (resp) {
                                            //         const trx = resp;
                                            //         if (trx.status && trx.transactionHash) {
                                            //             setStage({
                                            //                 message: `🔐 <a target="_blank" href="https://etherscan.io/tx/${trx.transactionHash}"><u>Transaction</u></a> Confirmed! <br/> Validating your payment...`
                                            //             });
                                            //             callAPI({
                                            //                 trx_hash: trx.transactionHash,
                                            //                 wallet: account,
                                            //                 subscription_id: subscription,
                                            //                 email: subscriberEmail,
                                            //                 nft_link: nftLink
                                            //             }, "validateAndCreate")
                                            //                 .then(function (resp) {
                                            //                     if (resp.status == 'SUCCESS') {
                                            //                         setStage({
                                            //                             success: true,
                                            //                             message: `🔐 <a target="_blank" href="https://bscscan.com/tx/${trx.transactionHash}"><u>Transaction</u></a> Confirmed! <br/> Congrats! Its validated`
                                            //                         });
                                            //                     } else {
                                            //                         setStage({
                                            //                             error: true,
                                            //                             message: `Error occured while validating your <a target="_blank" href="https://bscscan.com/tx/${trx.transactionHash}"><u>Transaction</u></a>`
                                            //                         });
                                            //                     }
                                            //                 })
                                            //         }
                                            //     })
                                            //     .catch(function (error) {
                                            //         console.log("Trx error: %s", error.message);
                                            //         setStage({
                                            //             message: `${error.message}`,
                                            //             error: true
                                            //         });
                                            //     });
                                        }}
                                                disabled={!eligible}>{btnText}</Button>
                                    </div>
                                }
                            </div>
                            <hr/>
                            <p className="text-light">{disclaimer}</p>
                        </>
                    )
            }


        </Modal>
    )
}

export default ServiceSubscription