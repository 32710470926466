import React, { useState } from 'react'
import Switch from 'react-switch'

function SwitchToggle({label, onChange, isChecked, inputName}) {
//   return (
	// <div className="switch isChecked">
	// 	<label></label>
	// 	<input type="checkbox" checked="" style={{display: 'none'}} />
	// 	{/* <span className="switchery switch-handle">
	// 		<small></small>
	// 	</span> */}
	// </div>
//     this.handleChange = this.handleChange.bind(this);

	const [checked, setChecked] = useState(isChecked)
	const handleChange = (checked) => {
		setChecked(checked);
		if(onChange){
			onChange(checked)
		}
	}

	return (
	<label className="switch-label d-flex mx-3">
		<span className="text-light mr-3">{label}</span>
		<Switch 
			onChange={handleChange}
			checked={checked} 
			checkedIcon={false}
			uncheckedIcon={false}
			onColor="#2ae3fd"
			// offColor="2ae3fd"
			name={inputName}
		/>
	</label>
	);
}

export default SwitchToggle