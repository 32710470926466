import React from 'react'
import { Link } from "react-router-dom";
import ShareBtn from '../atoms/share-btn'
import avatarImg from '../../assets/img/avatars/avatar_1.png'


function ProfileHeader({dp, userName}) {
	return (
		<div className="infos">
			<div className="container">
				<div className="row flex-wrap align-items-center justify-content-between">
					<div className="col-md-auto mr-20">
						<div className="avatars d-flex space-x-20 align-items-center">
							<div className="avatar_wrap">

								<img className="avatar avatar-lg" src={dp ? dp : avatarImg} alt="" />
							</div>
							<h5>{userName}</h5>
						</div>
					</div>
					<div className="col-md-auto">
						<div className="d-flex flex-wrap justify-content-end space-x-20 mb-20_reset header-buttons">
							<div className="d-flex flex-wrap justify-content-end space-x-20">
								{/* <div className="mb-20">
									<ShareBtn/>
								</div> */}
								<div className="mb-20">
									<div className="more">
										<div className="icon">
											<Link to="/edit-profile"><i className="ri-edit-line"></i></Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ProfileHeader