import React from 'react'

function Cube() {
  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.42969 3L10.0013 1.5L12.5728 3" stroke="#2AE3FD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 1.5V5.25" stroke="#2AE3FD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M12.5728 21L10.0013 22.5L7.42969 21" stroke="#2AE3FD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 22.5V18.75" stroke="#2AE3FD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M1 9.7275V6.75047L3.49141 5.27344" stroke="#2AE3FD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1 6.75L4.15422 8.625" stroke="#2AE3FD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M18.9992 14.2734V17.2505L16.5078 18.7275" stroke="#2AE3FD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.9979 17.25L15.8438 15.375" stroke="#2AE3FD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M3.49141 18.7505L1 17.2505V14.2734" stroke="#2AE3FD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1 17.25L4.12375 15.375" stroke="#2AE3FD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M16.5078 5.27344L18.9992 6.75047V9.7275" stroke="#2AE3FD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.9979 6.75L15.8438 8.625" stroke="#2AE3FD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M10 15V12L12.5716 10.5" stroke="#2AE3FD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M10.0013 12L7.42969 10.5" stroke="#2AE3FD" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
    </svg>

  )
}

export default Cube