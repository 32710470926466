import React from 'react'

function Tooltip({title, text, placement, classNames}) {
	return (
		<i className={`ri-information-line icon text-dark my-auto ${classNames}`} data-toggle="tooltip" data-placement={placement} title={title} data-original-title={text}></i>
		// <i className="ri-information-line icon icon-corner text-dark my-auto text-light" data-toggle="tooltip" data-placement="bottom" data-original-title={title} title={text}></i>
	)
}

export default Tooltip
