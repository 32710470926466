import React from 'react'
import nft_1 from '../../assets/img/nfts/1.jpg'
import nft_2 from '../../assets/img/nfts/2.jpg'
import nft_3 from '../../assets/img/nfts/3.jpg'
import nft_4 from '../../assets/img/nfts/4.jpg'
import nft_5 from '../../assets/img/nfts/5.jpg'
import nft_6 from '../../assets/img/nfts/6.jpg'
import nft_7 from '../../assets/img/nfts/7.jpg'
import RewardCard from '../molecules/RewardCard'
import PageTitle from '../atoms/pageTitle'
import PageBanner from '../molecules/page-banner'

function NFTList() {
	const nftCards = [
		{img: nft_1, tier: '1', title: 'Lilac Crown'},
		{img: nft_2, tier: '2', title: 'Indigo Third Eye'},
		{img: nft_3, tier: '3', title: 'Sapphire Throat'},
		{img: nft_4, tier: '4', title: 'Jade Heart'},
		{img: nft_5, tier: '5', title: 'Ivory Solar Plexus'},
		{img: nft_6, tier: '6', title: 'Coral Sacral'},
		{img: nft_7, tier: '7', title: 'Ruby Root'},
	]
  return (
	  <>
	<PageBanner/>
	<PageTitle text1={"SEED NFTs"} classNames="bg-dark" />
	<div className="col-4 mx-auto text-center mb-5">
		<p>Through our social media presence, we loop in the entire NFT community, as well as everyone interested in <span className="text-primary">NFT world</span>, on the latest industry news from across the globe, in addition to center-ataging NFT talent <span className="text-primary">through our Weekly Discord</span> Creator AMA sessions.</p>
	</div>
    <div className='container'>
				<div className="d-flex row">
					{nftCards.map(
						card => <RewardCard 
								id = {1}
								image = {card.img}
								title = {card.title}
								tier = {card.tier}
								classNames="col-12 col-md-3"
							/>
					)}
				</div>
    </div>
</>
  )
}

export default NFTList