export const shorter = (str, length = 6) => {
	try {
		return `${str.slice(0, length)}...${str.slice(str.length - 4, str.length)}`;
	} catch (err) {
		return '';
	}
};

export const truncate = (str, length = 100) => {
    return str.length > length ? str.substring(0, length) + "..." : str;
}

export const prettyNumber = (nb) => {
	let finalNumber = nb;
	// if(nb >= 1000000){
	// 	finalNumber = nb/1000000 + "M"
	// }

	return parseInt(finalNumber) <= 0? (parseFloat(finalNumber).toFixed(4)).toLocaleString() : parseFloat(finalNumber).toLocaleString();
}
