import React from 'react'
import Button from '../atoms/button'
import PageTitle from '../atoms/pageTitle'
import PageBanner from '../molecules/page-banner'
import SectionHeader from '../molecules/sectionHeader'

function NFTStaking() {
  return (
	
	<>
	<PageBanner/>
	<PageTitle text1={"iAI Seed NFT Staking"} aboveTitle={"Active Now"} classNames="bg-dark" />
	<div className='container'>
		<SectionHeader text="Live Staking Feed"/>
		<div className="row d-flex border border-light rounded p-5 mb-5">
			<div className='d-flex flex-column text-center mb-3 col-12 col-md-4'>
				<h5>Total Minting Power Staked</h5>
				<span>123</span>
			</div>
			<div className='d-flex flex-column text-center mb-3 col-12 col-md-4'>
			<h5>Total Minting Power Unstaked</h5>
				<span>123</span>
			</div>
			<div className='d-flex flex-column text-center mb-3 col-12 col-md-4'>
			<h5>Total iAI Tokens Claimed</h5>
				<span>123</span>
			</div>
			<div className='d-flex flex-column text-center mb-3 col-12 col-md-4'>
			<h5>Start Time</h5>
				<span>123</span>
			</div>
			<div className='d-flex flex-column text-center mb-3 col-12 col-md-4'>
			<h5>Your Total iAI Tokens Earned</h5>
				<span>123</span>
			</div>
		</div>
		<SectionHeader text="Your Stake"/>
		<div className="row d-flex border border-light rounded p-5 mb-5">
			<div className='d-flex flex-column text-center mb-3 col-12 col-md-6'>
				<h5 className="mb-5">Your UnStaked NFT(s) minting Power</h5>
				<Button classNames="btn btn-primary mx-auto">Stake NFT(s)</Button>
			</div>
			<div className='d-flex flex-column text-center mb-3 col-12 col-md-6'>
				<h5 className="mb-5">Your Staked NFT(s) minting Power</h5>
				<Button classNames="btn btn-primary mx-auto">Stake NFT(s)</Button>
			</div>
		</div>
	</div>
	</>
  )
}

export default NFTStaking