import * as React from 'react';

function SvgClose(props) {
  return (
    <svg
      width={29}
      height={30}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className=""
      {...props}>
      <path
        d="M21.213 7.95l-7.071 7.071m0 0L7.071 7.95m7.071 7.071l7.071 7.071m-7.071-7.071l-7.071 7.071"
        stroke="red"
        strokeWidth={3}
      />
    </svg>
  );
}

export default SvgClose;
