import React from 'react'
import Switch from 'react-switchery'
import Button from '../atoms/button'
import Dropdown from '../atoms/dropdown'
import PageTitle from '../atoms/pageTitle'
import ActivityListItem from '../molecules/ActivityListItem'
import PageBanner from '../molecules/page-banner'
import SectionHeader from '../molecules/sectionHeader'

function Activity() {
	const categoryButtons = [
		{text: 'All', icon: 'ri-function-line', category: 'all'},
		{text: 'Category 1', icon: 'ri-gamepad-line', category: 'all'},
		{text: 'Category 2', icon: 'ri-flashlight-line', category: 'all'},
		{text: 'Category 3', icon: 'ri-quill-pen-line', category: 'all'},
		{text: 'Category 4', icon: 'ri-headphone-line', category: 'all'},
		{text: 'Category 5', icon: 'ri-global-line', category: 'all'},
	]
	const sortByDropdown = [
		{text: 'All'},
		{text: 'Newest'},
		{text: 'Lowest Price'},
		{text: 'Ending Soon'},
		{text: 'Most Views'},
		{text: 'Most Likes'},
	]
	return (
		<>
			<PageBanner/>
			<PageTitle text1="Activity Feed" />
			<div className="container">
				<div className="row d-flex justify-content-center py-3">
					{categoryButtons.map(btn=> 
						<Button classNames={'btn btn-sm border-0 mx-2 bg-dark'} empty><i className={`${btn.icon} mr-1`}></i>{btn.text}</Button>
					)}
				</div>
			</div>
			<div className="container">
				<SectionHeader text="Latest Activity"/>
				<div className="d-flex flex-column flex-md-row justify-content-between py-5">
					<div className="d-flex mb-3 mb-md-0">
						<span className="mr-2">FILTER BY</span>
						<Switch
						        onChange={console.log('change')}
							label='On Bid'
							options={{
								color: '#474F79',
								size: 'small'
							}}
							checked
						/>
						<span className="ml-2 mr-2">Open for bidding</span>
						<Switch
						        onChange={console.log('change')}
							label='On Bid'
							options={{
								color: '#474F79',
								size: 'small'
							}}
							checked
						/>
						<span className="ml-2">Sold</span>
					</div>
					<div className="d-flex justify-content-start justify-content-md-end">
						<span className="mr-2">SORT BY</span>
						<Dropdown text="Sort By" items={sortByDropdown} />
					</div>
				</div>
				<div className="row justify-content-center">
					<div className="col-lg-6">
						<ActivityListItem/>
						<ActivityListItem/>
						<ActivityListItem/>
					</div>
				</div>
			</div>
		</>
	)
}

export default Activity