import Button from "../../atoms/button";
import heroImg from "../../../assets/img/bg/9022_Banner.png";
import heroImgMobile from "../../../assets/img/bg/9022_banner_mobile.png";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

export default function Slide1() {
  return (
    <div
      className="hero__1 bg-dark mb-5 mt-5"
      style={{ background: `url(${isMobile ? heroImgMobile : heroImg})`, height: "85vh", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
    ></div>
  );
}
