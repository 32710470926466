import {Link} from "react-router-dom"
import {truncate} from "../../utils/string"

function NewsCard({article, classNames}) {
    return (
        <div className={classNames}>
            <div className="card blog has_style_grid mr-0 mr-md-3">
                <div className="row align-items-center">
                    <div className="col-row col-12">
                        <div className="blog-img">
                            <Link to={`/article/${article?.slug}`}>
                                <img src={article?.thumbnail} alt=""/>
                            </Link>
                        </div>
                    </div>
                    <div className="col-row col-12">
                        <div className="blog-wrap mb-3">
                            <Link to={`/article/${article?.slug}`}>
                                <small className="text-muted">{article?.date}</small>
                                <h4 className="blog-title mb-1">
                                    {article?.title}
                                </h4>
                                <p className="blog-excerpt">
                                    {truncate(article?.description)}
                                </p>
                            </Link>
                        </div>
                        <Link to={`/article/${article?.slug}`}>Read More</Link>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default NewsCard