import React, { useRef, useState } from 'react'
import placeholder from '../../assets/img/bg/banner_placeholder.png'
import Button from '../atoms/button';

function ProfileHero({banner, handleDelete}) {
	const imgRef = useRef();
	const [showDelete, setShowDelete] = useState(false);
	const handleImageChange = (e)=>{
		var reader = new FileReader();
		reader.onload = function (e) {
			imgRef.current.src = e.target.result;
			setShowDelete(true);
		}
		reader.readAsDataURL(document.querySelector('[name=banner_img]').files[0]);
	}
	const handleDeleteImg = ()=>{
		imgRef.current.src = placeholder;
		setShowDelete(false)
		handleDelete();
	}
	return (
		<div className="mb-50">
			<div className="hero__profile">
				<div className="tabs">
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb default mb-0">
							<li className="breadcrumb-item"><a href="/">Home</a></li>
							<li className="breadcrumb-item active" aria-current="page">Edit profile</li>
						</ol>
					</nav>
				</div>
				<div className="cover">
					<img src={banner || placeholder} id="profileBanner" alt="" ref={imgRef}/>
					<div className="space-x-10 d-flex banner-btn-container">
						<div id="boxUpload">
							<button className="btn btn-dark mr-3"> Upload New Photo </button>
							<input id="imageUpload" type="file" name="banner_img" placeholder="Photo" required="" capture="" onChange={handleImageChange}/>
							{(banner || showDelete) && <Button className="btn btn-white" onClick={handleDeleteImg}>Delete</Button>}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ProfileHero