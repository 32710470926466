import React from 'react'
import { Link } from 'react-router-dom'
import CopyBtn from './copy-btn'
import {shorter} from "../../utils/string";
import CollectionCategory from "./CollectionCategory";

function ItemDetailFooter({nft}) {
	return (
		<div className="creators pt-3 mb-2" style={{borderTop: "2px solid #2ae3fd"}}>
			<div className="d-flex justify-content-between mb-3">
				<Link to="/auction-house">
					<CollectionCategory category={nft?.collection?.category} />
				</Link>

				<div className="d-flex align-items-center border-top-1">
					<h6 className="color_text text-nowrap mr-2">Token ID</h6>
					<div className="d-flex"> {nft?.token_id} <CopyBtn copyText={nft?.token_id}/> </div>
				</div>
			</div>
			<div className="d-flex justify-content-between">
				<h6 className="color_text text-nowrap mr-2">Contract Address</h6> <div className="d-flex"><p className="text-right">{shorter(nft?.collection?.address)}</p><CopyBtn copyText={nft?.collection?.address} /></div>
			</div>
		</div>
	)
}

export default ItemDetailFooter