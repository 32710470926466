import React from 'react'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { ShimmerText, ShimmerThumbnail, ShimmerTitle } from "react-shimmer-effects";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import callAPI from '../../api';
import {Helmet} from "react-helmet";

function Article(props) {


	const {slug} = useParams();

	const [article, setArticle] = useState(null);
	const [recentArticles, setRecentArticles] = useState(null);
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		callAPI({page:1, page_length:3}, 'getArticlesList').then((data)=>{
			if(data.status==='SUCCESS'){
				setRecentArticles(data.payload.articles);
				setLoading(false);
			}else if(data.message==='NO_ARTICLES_FOUND'){
				setLoading(false);
			}
		})
	}, []);

	useEffect(() => {
		callAPI({slug: slug}, 'getSingleArticle').then((data)=>{
			if(data.status==='SUCCESS'){
				setArticle(data.payload);
				setLoading(false);
			}else if(data.message==='INVALID_ARTICLE'){
				setLoading(false);
			}
		})
	}, []);

	const renderContent = ()=>{
		return(
			loading
			?
				<>
				<ShimmerTitle line={2} gap={10}/>
				<ShimmerThumbnail height={150} width={300} rounded />
				<ShimmerText line={10} gap={10}/>
				</>
			:<>
				<img className="mb-30 img-fluid w-full img_article" alt="" src={article?.thumbnail}/>

				<h1 className="mb-40">{article?.title}</h1>
	
				<div className="blog-author-detail d-flex align-items-center">
					<span className="font-semibold text-gray">{article?.date}</span>
				</div>

				<div className="inner" dangerouslySetInnerHTML={{__html: article?.body}}/>
			</>
		)
	}

	const title = article?.title;
	const img = article?.thumbnail;
	
	return (

		<div className="container article_page pt-100">
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{title}</title>
				<meta name="twitter:title" content={title}/>
				<meta name="twitter:image" content={img}/>
				<meta property="og:title" content={title}/>
				<meta property="og:image:secure_url" content={img}/>
				<meta property="og:image:type" content="image/png"/>
			</Helmet>
				<div className="main row" id="main-content">
					<div className="col-lg-8">
					<div id="content">
						<div className="article_wrap mt-0">
						<div className="content">
							{renderContent()}
						</div>
				
						</div>
					</div>
					</div>
					<div className="col-lg-4">
					<aside className="blog_sidebar">
						<div className="widget">
						<h3 className="widget-title text-dark">Share</h3>
						<div className="widget-wrap">
							<div className="social-widget">
							<a href="">
								<div className="social_link facebook">
									<FacebookShareButton url={window.location.href}><i className="ri-facebook-line"></i></FacebookShareButton>
								</div>
							</a>
							<a href="">
								<div className="social_link twitter">
									<TwitterShareButton url={window.location.href}><i className="ri-twitter-line"></i></TwitterShareButton>
								</div>
							</a>
							<a href="">
								<div className="social_link telegram">
									<TelegramShareButton url={window.location.href}><i className="ri-telegram-line"></i></TelegramShareButton>
								</div>
							</a>
							<a href="">
								<div className="social_link whatsapp">
									<WhatsappShareButton url={window.location.href}><i className="ri-whatsapp-line"></i></WhatsappShareButton>
								</div>
							</a>
							</div>
						</div>
						</div>
						<div className="widget">
						<h3 className="widget-title text-dark">Latest Articles
						</h3>
						<div className="widget-wrap">
							<div className="Recent_Articles-widget">
								<div className="swiper-container sidebar_posts">
									<div className="swiper-wrapper">
										<div className="swiper-slide">
											{recentArticles &&
												recentArticles.map(article=>
															<article className="item" key={Math.random()}>
																<Link to={`/article/${article.slug}`} className="thumb hover-effect">
																	<img src={article.thumbnail} alt=""/>
																</Link>
																<div className="info">
																	<h4 className="title">{article.title}</h4>
																	<div className="time">{article.date}</div>
																</div>
															</article>
													)
											}
										</div>
									</div>
								</div>
							</div>
						</div>
						</div>
					
						<div className="widget">
						<h3 className="widget-title">Categories</h3>
					
						<div className="widget-wrap">
							<ul>
								<li className="cat-item"><Link to="/news/announcements">Announcements</Link></li>
								<li className="cat-item"><Link to="/news/project-updates">Project Updates</Link></li>
								<li className="cat-item"><Link to="/news/in-the-media">In the Media</Link></li>
								<li className="cat-item"><Link to="/news/artist-curations">Artist Curations</Link></li>
								<li className="cat-item"><Link to="/news/spatial-releases">Spatial Releases</Link></li>
							</ul>
					
					
						</div>
						</div>
					
					
					</aside>        </div>
				</div>
				</div>	
	)
}

export default Article