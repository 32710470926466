import React, { useState } from 'react'

function AccordionItem({id, title, number, children, active}) {
	const [show, setShow] = useState(active? true : false)
	const handleCollapse = (id) => {
		document.querySelector(`.collapse`).classList.remove('show')
		show 
			?  document.querySelector(`#${id}.collapse`).classList.remove('show')
			:  document.querySelector(`#${id}.collapse`).classList.add('show')
		setShow(!show)
		
	}	
	return (
		<div className="accordion-item">
			<div className="d-flex justify-content-between" onClick={()=>handleCollapse(id)}>
				<h4 className="accordion-header" id={id}>
					{number&&<span className="number mr-4">{number}</span>}
					{title}
				</h4>
				<i className={`ri-arrow-${show ? 'up' : 'down'}-s-line ri-2x my-auto mr-3`}></i>
			</div>
			<div id={id} className={`accordion-collapse collapse ${show ? 'show' : ''}`}>
				<div className="accordion-body">
					{children}
				</div>
			</div>
		</div>
	)
}

export default AccordionItem