import React from "react";
import { Link } from "react-router-dom";
import {useSystem} from "../../hooks/use-system";
import Button from "../atoms/button";
import CopyBtn from "../atoms/copy-btn";
function RewardCard({
  isAuthorized,
  id,
  owner,
  cardName,
  tier,
  title,
  available,
  total,
  uniqueCount,
  price,
  mintableAmount,
  currency,
  mintableCurrency,
  image,
  onSale,
  sold,
  expirationTime,
  card,
  listed,
  isAdmin,
  classNames,
  slug
}) {
  const system = useSystem();
  return (
    <div className={`${classNames ? classNames : 'col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-3'}`}>
      <Link to={`/details/${slug}` } className="">
        <div className="card card__item four">
          <div className="card_body space-y-10">
            <div className="creators space-x-10">
              <div className="avatars space-x-3 w-100">
                  <img
                    src={
                      "https://dapps.blacklemon.wtf/assets/img/logos/iai_logo_black.jpeg"
                    }
                    alt="Avatar"
                    className="avatar avatar-sm mr-2"
                  />
                <Link to={isAdmin ? '/admin-detail' : `/details/${slug}` }><p className="text-light">inheritance Art</p></Link>
              </div>
            </div>
            <div className="card_head">
              <a href="#"><img src={image} alt="item img"/></a>
            </div>


              {tier &&  <h6 className="card_title">Tier {tier}</h6>}
              <h6 className="card_title">{title}</h6>
              <div className="hr mt-3"> </div>
              <div className="d-flex justify-content-between">
                <Link to={`/details/${slug}` } className=""><i className="ri-eye-line mr-2"></i>View Item</Link>
                <div className="my-auto">
                  {/* <i className="ri-gift-line ml-2 border rounded-circle p-1 icon-small hover my-auto"></i> */}
                </div>
              </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default RewardCard;
