import React, {useEffect, useState} from 'react';
import Modal from '../../../atoms/modal';
import './style.css'
import {useSystem} from '../../../../hooks/use-system';

import InputRow from '../../../molecules/input-row'
import Input from '../../../atoms/input'
import Button from '../../../atoms/button'
import callAPI from "../../../../api";
import {UseContractMethod} from "../../../../hooks/use-dapp";
import {useContract} from "../../../../hooks/use-contract";
import {useEthers} from "@usedapp/core";
import config from "../../../../config";
import {Contract} from "@ethersproject/contracts";
import {useInterface} from "../../../../hooks/use-interface";
import addresses from "../../../../config/addresses";


function CreateCollection({...props}) {

    const [title, setTitle] = useState('Create Collection');
    const [categories, setCategories] = useState([]);
    const [trxMessage, setTrxMessage] = useState('Create Now');
    const [roytaltiesMessage, setRoytaltiesMessage] = useState('Please sign another transaction for Royalties...');
    const [created, setCreated] = useState(false);
    const [createdContract, setCreatedContract] = useState(null);


    const [scData, setSCData] = useState({
        name: "", code: "", type: "", category: "", royalties: ""
    });

    const {account, error} = useEthers();

    const [isLoading, setIsLoading] = useState(false)

    const contract = useContract();
    const intrf = useInterface();

    const {
        state: state721,
        send: send721,
        events: events721
    } = UseContractMethod(contract?.auctionHouse, "createERC721Collection");

    const {
        state: state1155,
        send: send1155,
        events: events1155
    } = UseContractMethod(contract?.auctionHouse, "createERC1155Collection");

    const {
        state: stateRoyalties,
        send: sendRoyalties,
        events: eventsRoyalties
    } = UseContractMethod(createdContract, "setRoyaltyPercentage");


    const updateCreatedContract = () => {
        if (createdContract == null) {
            let ct;
            if (scData.type === config.constants.CONTRACT_TYPE.ERC721) {
                ct = new Contract(scData.address, intrf.erc721);
                setCreatedContract(ct);
            } else if (scData.type === config.constants.CONTRACT_TYPE.ERC1155) {
                ct = new Contract(scData.address, intrf.erc1155);
                setCreatedContract(ct);
            }
        }
    }

    useEffect(() => {
        sendRoyalties(scData.royalties * 10);

    }, [createdContract])

    useEffect(() => {
        if (!created) {

            const stateStatus = state721 && state721.status != 'None' ? state721.status : state1155 ? state1155.status : null;
            const stateReceipt = state721 && state721.status != 'None' ? state721.receipt : state1155 ? state1155.receipt : null;
            const stateTrx = state721 && state721.status != 'None' ? state721.transaction : state1155 ? state1155.transaction : null;

            if (stateStatus === 'Exception') {
                setIsLoading(false);
            } else if (stateStatus === 'Mining') {
                setTrxMessage('🔐 Transaction signed! Deploying Contract...');
            } else if (stateStatus === 'Success') {
                scData.address = stateReceipt.logs[0].address
                scData.hash = stateTrx.hash;

                updateCreatedContract();

                setTrxMessage(`Contract deployed! Syncing with backend...`);

                callAPI(scData, 'createCollection').then((data) => {
                    if (data.payload && data.payload.collection) {
                        setCreated(true);
                        props.onSuccess();
                    }
                }).catch((err) => {
                    alert(err);
                });
            }
        }

    }, [error, events721, state721, events1155, state1155]);

    useEffect(() => {
        if (stateRoyalties.status === 'Exception') {
            setRoytaltiesMessage(`Not able to set royalties, please contact your Administrator: ${error}`);
        } else if (stateRoyalties.status === 'Mining') {
            setRoytaltiesMessage('🔐 Transaction signed! Updating Royalties...');
        } else if (stateRoyalties.status === 'Success') {
            setRoytaltiesMessage('Royalties are set successfully!');
        }

    }, [eventsRoyalties, stateRoyalties]);

    const createCollectionContract = () => {
        var dataisMissing = Object.values(scData).includes("");

        if (!dataisMissing) {
            if (scData.type == config.constants.CONTRACT_TYPE.ERC721) {
                setIsLoading(true);
                setTrxMessage('Please sign the transaction...');
                send721(scData.name, scData.code, `https://cdn.inheritance-art.com/auction/${account}/${scData.code}/metadata/`);
            } else if (scData.type === config.constants.CONTRACT_TYPE.ERC1155) {
                setIsLoading(true);
                setTrxMessage('Please sign the transaction...');
                send1155(`https://cdn.inheritance-art.com/auction/${account}/${scData.code}/metadata/`);
            } else
                alert('Contract Type not set')
        } else {
            alert('Please fill all fields!')
        }
    };


    const getCategories = () => {
        callAPI({}, 'getCategories').then((data) => {
            setCategories(data.payload.categories)
        });
    }

    const setSCDataAttr = (e) => {
        const attr = e.target.name;
        scData[attr] = e.target.value;
        setSCData(scData);
    }

    useEffect(() => {
        if (categories.length == 0)
            getCategories();
    }, [])

    const system = useSystem();
    return (
        <Modal {...props} title={title}>
            <div>
                {created ? (
                    <>
                        <p>Your Collection has been deployed and synced with backend successfully!</p>
                        <p>{roytaltiesMessage}</p>
                    </>
                ) : (
                    <form className="">
                        <InputRow title=""
                                  description=""
                                  required>
                            <div className={'col-sm-12'}>
                                Collection Name
                                <Input disabled={isLoading} name={"name"} placeholder="e.g. Collection A"
                                       onChange={setSCDataAttr}/>
                            </div>
                        </InputRow>
                        <InputRow title=""
                                  description=""
                                  required>
                            <div className={'col-sm-6'}>
                                Collection Code
                                <Input disabled={isLoading} placeholder="e.g. COLA" name={'code'}
                                       onChange={setSCDataAttr}/>
                            </div>
                            <div className={'col-sm-6'}>
                                Collectible Type
                                <select disabled={isLoading} className="form-control" name={'type'}
                                        onChange={setSCDataAttr}>
                                    <option value={''}>Please Choose</option>
                                    <option value={'ERC721'}>ERC721</option>
                                    <option value={'ERC1155'}>ERC1155</option>
                                </select>
                            </div>

                        </InputRow>


                        <InputRow title="Royalties Percentage (Max: 25%)"
                                  description=""
                                  required>
                            <div className={'col-12'}>
                                <Input type={'number'} max={25} disabled={isLoading} placeholder="e.g. 3" name={'royalties'}
                                       onChange={setSCDataAttr}/>
                            </div>
                        </InputRow>

                        <InputRow title="Category" description=""
                                  required>
                            <div className={'col-12'}>
                                <select disabled={isLoading} className="form-control" name={"category"}
                                        onChange={setSCDataAttr}>
                                    <option value={''}>Please Choose</option>
                                    {categories.map((c) => {
                                        return <option value={c.slug}>
                                            {c.name}
                                        </option>
                                    })}
                                </select>
                            </div>
                        </InputRow>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex">
                                {isLoading ? (
                                    <div className={'align-items-center'}> {trxMessage} </div>
                                ) : (
                                    <Button classNames="btn btn-primary" onClick={() => {
                                        createCollectionContract()
                                    }}>Create Now</Button>
                                )}
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </Modal>
    );
}

export default CreateCollection;
