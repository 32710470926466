import React from 'react'
import Accordion from '../atoms/Accordion'
import AccordionItem from '../atoms/AccordionItem'

function RequirementsAccordion() {
  return (
	<Accordion classNames={'requirements-accordion'}>
		<AccordionItem number='01' title="Check your PC requirements" active={true} id="requirements_1">
			<>
				<p className="text-light">To first ensure full compatibility, you must first make sure your PC is compatible with Sony’s SRD technology.</p>
				<p className="text-light"> You can check all the requirements and Specifications <a href="https://electronics.sony.com/more/spatial-reality-display/p/elfsr1" target={"_blank" } className="text-primary">here</a>.</p>
			</>
		
		</AccordionItem>
		<AccordionItem number='02' title="Subscribe to Spatial Spawns Service" id="requirements_2">
			<p className="text-light">After purchasing and receiving your very own SRD, and with a compatible PC up and running, you can start your yearly subscription to the inheritance Spatial Spawns service on the Spatial Spawns portal.</p>
		</AccordionItem>
		<AccordionItem number='03' title="Buy Spatial Reality Display" id="requirements_3">
			<p className="text-light">Once PC compatibility is checked, you can proceed to purchase a SRD directly from <a href="https://electronics.sony.com/more/spatial-reality-display/p/elfsr1" target={"_blank"} className="text-primary">Sony’s webshop</a>. </p>
		</AccordionItem>
		<AccordionItem number='04' title="Receive your NFT Hologram" id="requirements_4">
			<p className="text-light">Once payment has been verified, you can upload a high resolution image of your NFT on the Spatial Spawns portal for approval by the inheritance Art team. If approved, inheritance Art’s team of animators will get to work! Our animators will create a unique Spatial Reality hologram file off the submitted image, with an estimated turnaround time of 2-4 weeks per NFT, or sooner. Created Spatial Reality files will then be made available for you to download, and will remain available even after a subscription has ended. You can download your Spatial Reality files, load them into the Spatial Reality software on your PC, and display your hologram anywhere you like.</p>
		</AccordionItem>
	</Accordion>
  )
}

export default RequirementsAccordion