import React, {useEffect, useState} from 'react'
import Button from '../../atoms/button'
import Input from '../../atoms/input'
import Modal from '../../atoms/modal'
import InputRow from '../../molecules/input-row'
import {UseContractMethod} from "../../../hooks/use-dapp";
import {useContract} from "../../../hooks/use-contract";
import callAPI from "../../../api";
import web3 from "web3";
import addresses from "../../../config/addresses";
import Progress from "../../atoms/icons/Progress";

function StakeIAIModal({minStakeAmount, iaiBalance}) {

    const [approved, setApproved] = useState(false);
    const [staked, setStaked] = useState(false);
    const [isStaking, setIsStaking] = useState(false);
    const [stakeAmount, setStakeAmount] = useState(minStakeAmount);
    const [stakeMessage, setStakeMessage] = useState("");
    const contract = useContract();

    const {
        state: stateApproval,
        send: sendApproval,
        events: eventsApproval
    } = UseContractMethod(contract?.iai, "approve");

    const {
        state,
        send,
        events
    } = UseContractMethod(contract?.auctionHouse, "stake");

    useEffect(() => {
        if (state.status === 'Exception') {
            setIsStaking(false);
        } else if (state.status === 'Mining') {
            setStakeMessage('🔐 Transaction signed! Staking your iAI...')
        } else if (state.status === 'Success') {
            setStaked(true);
        }
    }, [state, events]);

    useEffect(() => {
        if (!approved) {
            if (stateApproval.status === 'Exception') {
                setIsStaking(false);
            } else if (stateApproval.status === 'Mining') {
                setStakeMessage('🔐 Transaction signed! Giving approval...');
            } else if (stateApproval.status === 'Success') {
                setApproved(true);
                setStakeMessage('✅ Approval given, Please sign the stake transaction...');
                send(web3.utils.toWei(stakeAmount));
            }
        }

    }, [stateApproval, eventsApproval]);

    const stake = () => {
        sendApproval(addresses.auctionHouse, web3.utils.toWei(stakeAmount));
        setIsStaking(true);
        setStakeMessage("Please sign the approval transaction...")
    }

    return (
        <Modal title="Stake iAI" classNames="bg-dark" containerClassNames="staking-modal">
            {staked ? (
                <p>You have staked successfully your iAI!</p>
            ) : (
                <>
                    <InputRow description={`You must stake at least ${minStakeAmount} iAI`} required>
                        <div className="max-input">
                            <input className="form-control" type={'number'} placeholder="Enter value" max={iaiBalance}
                                   value={stakeAmount}
                                   onChange={(e) => {
                                       let newValue = parseFloat(e.target.value) <= parseFloat(iaiBalance) ? e.target.value : iaiBalance;
                                       newValue = newValue < minStakeAmount ? minStakeAmount : newValue;
                                       setStakeAmount(newValue);
                                   }}/>
                            <button onClick={(e) => {
                                setStakeAmount(iaiBalance);
                            }}>Max
                            </button>
                        </div>
                    </InputRow>
                    <div className="d-flex justify-content-center">
                        {isStaking ? (
                            <p>{stakeMessage}</p>
                        ) : (
                            <Button classNames="btn btn-primary btn-lg w-100" onClick={stake}>Stake</Button>
                        )}

                    </div>
                </>
            )}


        </Modal>
    )
}

export default StakeIAIModal