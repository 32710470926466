import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/plugins/remixicon.css';
import './assets/css/plugins/bootstrap.min.css';
import './assets/css/plugins/swiper-bundle.min.css';
import './assets/css/style.css';
import './assets/css/custom_style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {SystemProvider} from "./hooks/use-system";
import SystemModals from "./components/organisms/modals";
import { DAppProvider } from "@usedapp/core";
import config from "./config";


ReactDOM.render(
    <React.StrictMode>
        <DAppProvider config={config.DappConfig}>
            <SystemProvider>
                <App/>
                <SystemModals/>
            </SystemProvider>
        </DAppProvider>
    </React.StrictMode>,
    document.getElementById('root')
)
;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
