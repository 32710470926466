import React from "react";
import PageTitle from "../atoms/pageTitle";

import partner1 from "../../assets/img/partners/partners-1.png";
import partner2 from "../../assets/img/partners/partners-2.png";
import partner3 from "../../assets/img/partners/partners-3.png";
import partner4 from "../../assets/img/partners/partners-4.png";
import partner5 from "../../assets/img/partners/partners-5.png";
import partner6 from "../../assets/img/partners/partners-6.png";
import partner7 from "../../assets/img/partners/partners-7.png";
import partner8 from "../../assets/img/partners/partners-8.png";
import partner9 from "../../assets/img/partners/partners-9.png";
// import partner9 from '../../assets/img/partners/9.png'
import art from "../../assets/img/art.png";
import art_1 from "../../assets/img/art_1.mp4";
import art_2 from "../../assets/img/art_2.mp4";
import art_3 from "../../assets/img/art_3.png";
import vincent from "../../assets/img/team/vince.png";
import mike from "../../assets/img/team/mike.png";
import brian from "../../assets/img/team/brian.png";
import gary from "../../assets/img/team/garry.png";
import roya from "../../assets/img/team/roya.png";
import thomas from "../../assets/img/team/thomas.png";
import matt from "../../assets/img/team/matt.png";
import tracey from "../../assets/img/team/tracey.png";
import placeholder from "../../assets/img/team/placeholder.png";
import vector_top from "../../assets/img/vector_top.png";
import vector_bottom from "../../assets/img/vector_bottom.png";
import iaiLogo from "../../assets/img/half-logo.png";
import left from "../../assets/img/left_par.png";
import right from "../../assets/img/right_par.png";
import CommunityLinks from "../molecules/community-links";
import SectionHeader from "../molecules/sectionHeader";
import AboutCard from "../atoms/AboutCard";
import AboutCardSm from "../atoms/AboutCardSm";

function About() {
  return (
    <>
      <div style={{ marginTop: "80px" }}>
        <PageTitle text1="inheritance Art" classNames="bg-dark" />
      </div>
      <div className="p-3 p-md-5 ml-5 text-left about-hero">
        <p>
          inheritance Art is an artificial intelligence and animation laboratory focused on creating hyper realistic and volumetric intelligent content. Over
          the next three years, inheritance Art aims to become a top leader in the NFT/ intelligent NFT space supported by our studio for mixed reality and
          hyper realistic productions.
        </p>
        <img className="larger-image" src={iaiLogo} />
      </div>
      <div className="my-5 absolute-section" style={{ position: "relative" }}>
        <img className="quote-image" src={art} />
        <div className="paragraph">
          <img src={left} className="left" />
          <p>
            inheritance Art’s <span className="text-primary">vision</span> is to grant everyone, everywhere the ability to enjoy, experience and obtain fine
            art, delivered through blockchain NFTs.
          </p>
          <img src={right} className="right" />
          <p className="mt-3">— Vincent Peters, Founder — </p>
        </div>
      </div>
      <div className="p-3 p-md-5 ml-5 text-left about-hero absolute-section">
        <div className="col-12 col-md-7">
          <div className="col-12 mb-3">
            <h2>Mission</h2>
          </div>
          <p className="text-light p-lg">
            inheritance Art’s mission is to pair pop-up culture and fine art with ground-breaking spatial reality technology, while leveraging blockchain and
            non-fungible tokens (NFT) to reach the masses. Through utilization of a highly functional marketplace, community creators will find a home among
            some of the most popular names of today and time-tested artists of the past.{" "}
          </p>
        </div>
        <video src={art_1} style={{ maxWidth: "850px" }} autoPlay loop />
      </div>
      <div className="section absolute-section" style={{ position: "relative" }}>
        <img src={vector_top} className="vector-top" />
        <img src={art_3} className="w-100" />
        <img src={vector_bottom} className="vector-bottom" />
      </div>
      <div className="container section">
        <div className="d-flex mb-5 row">
          <div className="col-12 mt-5">
            <SectionHeader text="Founding Team" />
            <div className="d-flex row">
              <AboutCard
                img={vincent}
                name="Vincent Peters"
                title="Chief Executive Officer & Founder"
                text="Vincent Peters is an engineer focused on the indefinite extension of the human experience via automation and artificial intelligence. Vincent is a graduate of West Point and has graduate certificates in Artificial Intelligence from Oxford and Blockchain Innovation and Business Application from MIT. Prior to founding inheritance, Vincent worked for Elon Musk's SpaceX and Starlink companies."
                socials={{
                  linkedIn: "https://www.linkedin.com/in/vincent-peters-2a493713/",
                  twitter: "https://twitter.com/realVincePeters",
                  instagram: "https://www.instagram.com/vincentpeters/",
                }}
              />
              <AboutCard
                img={mike}
                name="Mike Parra"
                title="Chief Advisor"
                text="Mr. Parra is an experienced Chief Executive Officer with a demonstrated history of working in the logistics and supply chain industry. Skilled in negotiation, budgeting, operations management, supply chain optimization, and freight. Strong business development professional with an Advanced Management Program focused in International Business from Duke University - The Fuqua School of Business.  "
                socials={{
                  linkedIn: "https://www.linkedin.com/in/mikeparradhl/",
                  twitter: "https://twitter.com/realmikeparra",
                  instagram: "https://www.instagram.com/realmikeparra/",
                }}
              />
              <AboutCard
                img={brian}
                name="Brian Lai"
                title="Chief Animator"
                text="Brian Lai is the Deputy Head of Department at the One Academy, the leading Computer Graphics school in Malaysia. Brian specializes in hyper realistic graphics and creating life-like digital content."
                socials={{
                  linkedIn: "https://www.linkedin.com/in/brian-lai-06388122a/",
                  instagram: "https://www.instagram.com/brianlaiii/?hl=en",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container section">
        <div className="d-flex mb-5 row">
          <div className="col-12 mt-5">
            <SectionHeader text="Advisors" />
            <div className="d-flex row justify-content-center">
              <AboutCardSm
                img={thomas}
                name="Thomas Ambrosia"
                title="Operations Advisor"
                text="Thomas brings extensive business experience in both traditional and emerging blockchain/crypto markets. He has operated within the blockchain space since 2015; advising several projects; managing his own due diligence operation (2017-2018), and most recently as the Chief of Employee Operations & Growth for a well-established blockchain project. Thomas operates with compliance in mind and is familiar with several aspects of blockchain start-up operations to include community management, operational development, content creation, and product development."
                socials={{
                  linkedIn: "https://www.linkedin.com/in/thomas-ambrosia-3bb296156/",
                  twitter: "https://twitter.com/AmbrosiaThom",
                }}
              />
              <AboutCardSm
                img={gary}
                name="Gary Sumner"
                title="Lead Auction House Advisor"
                text="Gary is an art and business entrepreneur who has become a prolific collector of 1/1 NFTs and digital assets. With his network of tens of thousands of artists and collectors, he has participated in over a thousand auctions and numerous collaborations to date. He has a history of strategic advisor roles for projects across Web3, NFT and Cryptocurrency dating back to 2016. As a decade-long artist-turned-collector, he stands out in the field for his role in mentoring and cultivating talented artists as they navigate the ever changing art landscape. His focus is to bridge the traditional world of physical fine art with the innovative renaissance in art that digital NFTs represent."
                socials={{
                  linkedIn: "https://www.linkedin.com/in/sumner-industries/",
                  twitter: "https://twitter.com/sendr0ck",
                  instagram: "https://www.instagram.com/sendrock/",
                }}
              />
              <AboutCardSm
                img={roya}
                name="Roya Ghassemi"
                title="Advisor of Art & Artist Curation"
                text="Roya is a top digital artist in the NFT space. Defining her signature style during a decade as a graphic designer and physical fine artist, she has rapidly expanded her network and art business. As her name and keen eye for aesthetic have become more well known, she continues to take on the role of art and artist curation for real world galleries and NFT exhibitions, as well as throughout the Metaverse. Working alongside collectors, artists and art business owners, she is uniquely positioned to continue to grow as an artist, while also playing a key role in the evolution of fine art NFTs and artists as the space continues to escalate and mature."
                socials={{
                  twitter: "https://twitter.com/_r0yart",
                  instagram: "https://www.instagram.com/r0yart/",
                }}
              />
              <AboutCardSm
                img={matt}
                name="Matt Hartog"
                title="Head of Communications"
                text="Matt has a decade of experience working in linguistics and social media. A world traveler and creative with a great eye for detail, Matt works to spread the word about inheritance Art's vision: to make beautiful and meaningful digital art accessible to everyone."
                socials={{
                  linkedIn: "https://www.linkedin.com/in/matt-hartog-6650ba253/",
                  twitter: "https://twitter.com/MattHLX",
                }}
              />
              <AboutCardSm
                img={tracey}
                name="Tracey Buck"
                title="Social Awareness Coodinator"
                text=""
                socials={{
                  twitter: "https://twitter.com/76423rd",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section mt-5">
        <div className="container mb-5">
          <CommunityLinks />
        </div>
        <div className="container py-5 text-center">
          <SectionHeader text="Our Professional Network" justify="center" />
        </div>
        <div className="row d-flex justify-content-center partners">
          <img src={partner1} className="img-fluid mb-3" />
          <img src={partner2} className="img-fluid mb-3" />
          <img src={partner3} className="img-fluid mb-3" />
          <img src={partner4} className="img-fluid mb-3" />
          <img src={partner5} className="img-fluid mb-3" />
          <img src={partner6} className="img-fluid mb-3" />
          <img src={partner9} className="img-fluid mb-3" />
          {/* <img src={partner9} className="img-fluid"/> */}
        </div>
      </div>
    </>
  );
}

export default About;
