import React from 'react'

function TabsContent({children, classNames}) {
	return (
		<div className={`tab-content ${classNames}`}>
			{children}
		</div>
	)
}

export default TabsContent
