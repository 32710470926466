import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../atoms/button'
import NewsCard from '../molecules/NewsCard'
import PageTitle from '../atoms/pageTitle'
import PageBanner from '../molecules/page-banner'
import { useParams } from "react-router-dom";
import { ShimmerPostItem, ShimmerPostList } from 'react-shimmer-effects'
import callAPI from '../../api'
import { isMobile } from 'react-device-detect'
import newsBanner from '../../assets/img/banners/news.jpg'
import newsBannerMobile from '../../assets/img/banners/mobile/news.jpg'

function Announcements() {
	const [articles, setArticles] = useState([]);
	const [page, setPage] = useState(1);
	const [hasNext, setHasNext] = useState(false);
	const [loading, setLoading] = useState(true)
	const {category} = useParams();

	const handleLoadMore = ()=>{
		setPage(page+1)
	}
	useEffect(() => {
		callAPI({page:page, page_length:6, category: category}, 'getArticlesList').then((data)=>{
			if(data.status==='SUCCESS'){
				setArticles([...articles, ...data.payload.articles]);
				setHasNext(data.payload.has_next)
				setLoading(false);
			}else if(data.message==='NO_ARTICLES_FOUND'){
				setLoading(false);
			}
		})
	}, [page, category]);

	const renderLoadMoreBtn = ()=>{
		return(

			hasNext && <div className="row d-flex justify-content-center">
				{ loading? <><span className='fas fa-circle-cog fa-spin'></span></> : <Button classNames="btn btn-primary w-auto" onClick={handleLoadMore}>Load More</Button> }
			</div>
		)
	}


  return (
	  <>
	<PageBanner img={ isMobile ? newsBannerMobile : newsBanner }/>
	<PageTitle text1={"News"} classNames="bg-dark" />
	<div className="col-10 col-md-4 mx-auto text-center mb-5">
		<p>Keep an eye out on our News to stay on top of all iAI project updates and accomplishments</p>
	</div>
    <div className='container'>
	<div className="row d-flex justify-content-center">
		{!isMobile &&  loading && <ShimmerPostList postStyle="STYLE_FOUR" col={3} row={2} gap={30} /> }	
		{isMobile && loading && <ShimmerPostItem card title text />}	
		{!loading && articles.map(article=><NewsCard article={article} classNames="col-12 col-md-4"/>)}	
		{!loading && articles.length===0 ? <h3 className="text-center">No articles found under this category</h3>:null}
	</div>
	{renderLoadMoreBtn()}
    </div>
</>
  )
}

export default Announcements