import React, { useEffect } from 'react'
import { useState } from 'react'
import {useSystem} from '../../hooks/use-system'
import { API, endpoints } from '../../utils/api'
import Button from '../atoms/button'
import SwitchToggle from '../atoms/switch'
import EditProfileHeader from '../molecules/edit-profile-header'
import ProfileHero from '../molecules/profile-hero'
import callAPI from '../../api'
import {useEthers} from "@usedapp/core";
import {useNavigate} from "react-router";

function EditProfile() {
	const system = useSystem();
	const {setError, setSuccess} = system;

	const {account} = useEthers();

	const client = API
	const [user, setUser] = useState(null)
	const [loading, setLoading] = useState(false)
	const [deleteBanner, setDeleteBanner] = useState(false)
	const [deleteDP, setDeleteDP] = useState(false)
	const [isArtist, setIsArtist] = useState(user? user.is_artist : false)
	const [profileData, setProfileData] = useState({ })

	useEffect(() => {
		if (user === null && account) {
			// setLoading(true)
			callAPI({}, "getUserProfile").then((data) => {
				if(data.payload && data.payload.user){
					setUser(data.payload.user);
					setProfileData(data.payload.user)
				} else{
					setUser({wallet: account});
				}
				// setLoading(false)
			});
		}

	}, [user, account])

	const handleChange = (e) => {

		let value = null;
		if(e.target.type === "checkbox"){
			value = e.target.checked;
		}else if(e.target.type === "file"){
			const fileObject = e.target.files[0]
			value = fileObject
		}else{
			value = e.target.value;
			e.target.parentElement.classList.remove('form-error')
		}

		setProfileData({
			...profileData,
			[e.target.name]: value

		});

	}
	const handleSubmit = ()=>{
		let canSubmit = true
		setLoading(true)
		document.querySelectorAll('input, textarea').forEach(el=>el.disabled=true)
		const profileForm = document.getElementById('profile-form')
		const data = new FormData(profileForm)
		// temporary fix, do something better
		data.append('username', document.getElementById('username').value)
		data.append('twitter', document.getElementById('twitter').value)
		data.append('discord', document.getElementById('discord').value)
		data.append('instagram', document.getElementById('instagram').value)
		data.append('twitter', document.getElementById('twitter').value)
		data.append('email', document.getElementById('email').value)
		data.append('bio', document.getElementById('bio').value)
		data.append('delet_dp', deleteDP)
		data.append('delet_banner', deleteBanner)
		// bad fix end
		for ( let key in profileData ) {
			data.append(key, profileData[key]);
		}
		data.append('wallet', account)
		if(!account){
			canSubmit = false
		}

		// data.append('is_artist', document.querySelector('[name="is_artist"]').checked)
		data.append('is_artist', false)
		const artworksTitles = {
			art_1: document.querySelector(`input.artwork-title[data-file-id="1"]`)?.value,
			art_2: document.querySelector(`input.artwork-title[data-file-id="2"]`)?.value,
			art_3: document.querySelector(`input.artwork-title[data-file-id="3"]`)?.value,
		}
		data.append('artwork_titles', JSON.stringify(artworksTitles));
		const profileImage = document.querySelector('[name=dp_img]').files
		const bannerImage = document.querySelector('[name=banner_img]').files
		if(profileImage){
			data.append('profile_image', profileImage[0]);
		}
		if(bannerImage){
			data.append('banner_image', bannerImage[0]);
		}

		document.querySelectorAll('input').forEach(el=>{
			if(el.name==='email'){
				const emailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
				const isValid = emailRegex.test(el.value)
				if(!isValid){
					el.parentElement.classList.add('form-error');
					canSubmit = false;
				}
			}else if(el.required && el.value.length===0){
				el.parentElement.classList.add('form-error');
				canSubmit = false;
			}
		})
		if(canSubmit){

			client.post(endpoints.UPDATE_PROFILE, data, { }).then(res => { 
				if(res.data.status==='SUCCESS'){
					setSuccess('Profile Updated Successfully!')
					setUser(res.data.payload.subscriber)
				}else if(res.data.message==='USERNAME_EXISTS'){
					setError('This username is taken! Please choose a new username.')
				}else{
					setError('Error updating profile. Please try again later. If the problem persists please contact support.')
				}
				setLoading(false);
				document.querySelectorAll('input, textarea').forEach(el=>el.disabled=false)
			})

		}else{
			if(!account){
				setError('Please connect your wallet to proceed')
			}else{

				setError('Make sure required the fields are filled')
			}
			document.querySelectorAll('input, textarea').forEach(el=>el.disabled=false)
			setLoading(false);
		}
	}
	return (
		<>
			<ProfileHero banner={user?.profile_banner} handleDelete={()=>setDeleteBanner(true)}/>
			<div className="container">
				<EditProfileHeader dp={user?.profile_dp} handleDelete={()=>setDeleteDP(true)}/>
				<div className={`box edit_box col-lg-9 space-y-30`}>
					<form id="profile-form">
			                <div className="row sm:space-y-20">
						<div className="col-lg-6 account-info">
							<h3 className="mb-20">Account info</h3>
							<div className="form-group space-y-10 mb-0">
								<div className="space-y-40">
									<div className="space-y-10">
										<span className="nameInput">Username</span>
										<input type="text" required className="form-control" value={profileData['username']} placeholder="Enter your user name" id="username" name="username"  onChange={handleChange}/>
									</div>
									<div className="space-y-10">
										<span className="nameInput d-flex justify-content-between">Email
											<span className="txt_xs">Your email for marketplace notifications</span>
										</span>
										<div className="confirm">
											<input type="email" required className="form-control" value={profileData['email']} placeholder="Enter your email" id="email" name="email"  onChange={handleChange}/>
										</div>
									</div>
									<div className="space-y-10">
										<span className="nameInput">Bio</span>
										<textarea style={{minHeight: '110px'}} value={profileData['bio']} placeholder="Add your bio" id="bio" name="bio"  onChange={handleChange}>{profileData['bio']}</textarea>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6 account-info">
							<h3 className="mb-20">Social Media</h3>
							<div className="form-group space-y-10 mb-0">
								<div className="space-y-40">
									<div className="space-y-10">
										<span className="nameInput">Twitter</span>
										<input type="text" className="form-control" value={profileData['twitter']} id="twitter" name="twitter"  onChange={handleChange}/>
									</div>
									<div className="space-y-10">
										<span className="nameInput">Discord</span>
										<input type="text" className="form-control" value={profileData['discord']} id="discord" name="discord"  onChange={handleChange}/>
									</div>
									<div className="space-y-10">
										<span className="nameInput">Instagram</span>
										<input type="text" className="form-control" value={profileData['instagram']} id="instagram" name="instagram"  onChange={handleChange}/>
									</div>
								</div>
							</div>
						</div>
			                </div>
					{/* <SwitchToggle label="I am an artist"  onChange={setIsArtist} isChecked={isArtist} inputName="is_artist"/> */}
					{/* <div className='my-3'></div>
			                <div className={`row sm:space-y-20 collapse-section ${isArtist ? '' : 'collapsed'}`}>
						<h3 className="mb-20">Top Artworks</h3>
						<div className="col-12 account-info">
							<div className="form-group space-y-10 mb-0">
								<div className="space-y-40 artworks">
									<div className="d-flex">
										<div className="space-y-10 col-6">
											<span className="nameInput">Artwork 1 Name</span>
											<input type="text" className="form-control artwork-title" value={profileData['art_1_name'] || getArtName(3)} name="art_1_name" data-file-id="1"   onChange={handleChange}/>
										</div>
										<div className="space-y-10 col-6">
											<span className="nameInput">Artwork 1 File Upload</span>
											<input type="file" className="form-control artwork-file" value="" name="art_1" id="art_1" data-file-id="1"  onChange={handleChange}/>
										</div>
									</div>
									<div className="hr"></div>
									<div className="d-flex">
										<div className="space-y-10 col-6">
											<span className="nameInput">Artwork 2 Name</span>
											<input type="text" className="form-control artwork-title" value={profileData['art_2_name'] || getArtName(3)} name="art_2_name" data-file-id="2"  onChange={handleChange}/>
										</div>
										<div className="space-y-10 col-6">
											<span className="nameInput">Artwork 2 File Upload</span>
											<input type="file" className="form-control artwork-file" value="" name="art_2" id="art_2" data-file-id="1"  onChange={handleChange}/>
										</div>
									</div>
									<div className="hr"></div>
									<div className="d-flex">
										<div className="space-y-10 col-6">
											<span className="nameInput">Artwork 3 Name</span>
											<input type="text" className="form-control artwork-title" value={profileData['art_3_name'] || getArtName(3)} name="art_3_name"  data-file-id="3"  onChange={handleChange}/>
										</div>
										<div className="space-y-10 col-6">
											<span className="nameInput">Artwork 3 File Upload</span>
											<input type="file" className="form-control artwork-file" value="" name="art_3" id="art_3" data-file-id="1"  onChange={handleChange}/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div> */}
					</form>
			                <Button onClick={handleSubmit} loading={loading}>{loading?<span className="fa fa-circle-cog fa-spin">Updating...</span>:'Update Profile'}</Button>
			            </div>
			</div>

		</>
	)
}

export default EditProfile