import React, { useEffect, useState } from "react";
import TabListItem from "../atoms/tab-list-item";
import TabsNavList from "../atoms/tabsNavList";
import TabPane from "../atoms/tabPane";
import TabsContent from "../atoms/tabsContent";
import ItemDetailHeader from "../atoms/item-detail-header";
import {useSystem} from "../../hooks/use-system";
import CopyBtn from "../atoms/copy-btn";
import { Link, useParams } from "react-router-dom";

import nft_1 from '../../assets/img/nfts/1.jpg'
import nft_2 from '../../assets/img/nfts/2.jpg'
import nft_3 from '../../assets/img/nfts/3.jpg'
import nft_4 from '../../assets/img/nfts/4.jpg'
import nft_5 from '../../assets/img/nfts/5.jpg'
import nft_6 from '../../assets/img/nfts/6.jpg'
import nft_7 from '../../assets/img/nfts/7.jpg'
import rw_1 from '../../assets/img/rewards/1.jpg'
import rw_2 from '../../assets/img/rewards/2.jpg'
import rw_3 from '../../assets/img/rewards/3.jpg'
import rw_4 from '../../assets/img/rewards/4.jpg'
import rw_5 from '../../assets/img/rewards/zenith.gif'
import Button from "../atoms/button";

function NFTDropDetail({ contract, data, showInfo, showBuyNow, showSell, isOwner, isAuthorized, isCanPutOnSale }) {

  const description = 'The Root chakra is linked to a sense of security and feeling of home during the earthly journey of the body. The chakra is represented by the red color,a color of action associated with stimulating properties. With its focus on skin texture,the Ruby Root SEED NFT represents the life energy,the home of the body,and the sense of grounding.'
  const mintingPower = '1'
  const properties = 'Base'
  const tier = 1
  const tokenID = 123

  const {slug} = useParams()
  
	const [nft, setNFT] = useState(null);
	const nftCards = {
		'lilac-crown': {img: nft_1, tier: '1', title: 'Lilac Crown', description: 'The Crown chakra is responsible for an individual’s spiritual connection, sense of self, balance, wholesomeness, and the link between the soul and the world around it. It is represented by the color purple, which in its natural rarity, carries a deep association with royalty and luxury. With the element of the headdress, the Lilac Crown SEED NFT simulates a royal crown and represents eminence, poise, and value.'},
		'indigo-third-eye': {img: nft_2, tier: '2', title: 'Indigo Third Eye', description: 'The Third Eye chakra is responsible for an individual’s power of imagination, intuition, clarity, and critical thinking.  It is associated with the color indigo which is made up of two colors blue and red, each with its own functions. With red’s energy and blue’s calm, indigo is a color that inspires reflection and self-awareness. With the element of the eyeshades, the physical eyes are veiled, and the Indigo Third Eye SEED NFT embodies extra-sensory perception, inwardness, and insight.'},
		'sapphire-throat': {img: nft_3, tier: '3', title: 'Sapphire Throat', description: 'The Throat chakra symbolizes self expression, expression of truth, creative expression, communication, perfect form and patterns. The Throat chakra is associated with the color blue, which calls feelings of calmness, serenity, stability, and reliability. With the element of the neckband, the Sapphire Throat SEED NFT limelights self-expression, connection, and integrity.'}, 
    'jade-heart': {img: nft_4, tier: '4', title: 'Jade Heart', description: 'The Heart chakra guides the feelings of trust, generosity, and gratitude in a person, as well as governs an individual’s sense of peace and openness to change. It is associated with the color green, which represents earth, life, love, and change. With the element of the baroque necklace spotlighting the powerful chest area, the Jade Heart SEED NFT presents elegance, sensibility, and magnetism.'}, 
    'solar-plexus': {img: nft_5, tier: '5', title: 'Solar Plexus', slug: 'solar-plexus', description: 'The Solar Plexus chakra is believed to be related to personal power and discipline. It is often associated with the sun which is believed to be the core of solar bodies. The yellow chakra is called the solar plexus chakra because of its color, fiery yellow, just like the sun. Inspired by the “No Retreat, No Surrender” idiom, the shield element of the Ivory Solar Plexus SEED NFT presents willpower, fortitude, and a strong sense of self'},
		'coral-sacral': {img: nft_6, tier: '6', title: 'Coral Saclar', slug: 'coral-sacral', description: 'The Sacral chakra is closely related to the sensual and creative power. The chakra is represented by the orange color, representing activity, purity, and increasing consciousness and lust. With the bodily ornament of the arm-band inspired by the “wear your heart on your sleeve” idiom, the Coral Sacral SEED NFT epitomizes sensuality, intensity, and passion.'},
		'ruby-root': {img: nft_7, tier: '7', title: 'Ruby Root', slug: 'ruby-root', description:  'The Root chakra is linked to a sense of security and feeling of home during the earthly journey of the body. The chakra is represented by the red color,a color of action associated with stimulating properties. With its focus on skin texture,the Ruby Root SEED NFT represents the life energy,the home of the body,and the sense of grounding.'},
		'zenith-1': {img: rw_1, tier: null, title: 'ZENITH - inheritance Art NFT Reward', slug: 'zenith-1', description: 'There is a sacred synergy beyond all word in the alignment of the right elements, & within the rightful transmission of energies, they shall blossom into consciousness in a synthesis of colors.  This collection has a total supply of 56, with each NFT a 1 of 4.'},
		'zenith-2': {img: rw_2, tier: null, title: 'ZENITH - inheritance Art NFT Reward', slug: 'zenith-2', description: 'There is a sacred synergy beyond all word in the alignment of the right elements, & within the rightful transmission of energies, they shall blossom into consciousness in a synthesis of colors.  This collection has a total supply of 56, with each NFT a 1 of 4.'},
		'zenith-3': {img: rw_3, tier: null, title: 'ZENITH - inheritance Art NFT Reward', slug: 'zenith-3', description: 'There is a sacred synergy beyond all word in the alignment of the right elements, & within the rightful transmission of energies, they shall blossom into consciousness in a synthesis of colors.  This collection has a total supply of 56, with each NFT a 1 of 4.'},
		'zenith-4': {img: rw_4, tier: null, title: 'ZENITH - inheritance Art NFT Reward', slug: 'zenith-4', description: 'There is a sacred synergy beyond all word in the alignment of the right elements, & within the rightful transmission of energies, they shall blossom into consciousness in a synthesis of colors.  This collection has a total supply of 56, with each NFT a 1 of 4.'},
		'zenith-5': {img: rw_5, tier: null, title: 'ZENITH - inheritance Art NFT Reward', slug: 'zenith-5', description: 'There is a sacred synergy beyond all word in the alignment of the right elements, & within the rightful transmission of energies, they shall blossom into consciousness in a synthesis of colors.  This collection has a total supply of 56, with each NFT a 1 of 4.'},
		// 'test': {img: nft_4, tier: '4', title: 'Jade Heart'},
		// 'test': {img: nft_5, tier: '5', title: 'Jade Heart'},
		// 'test': {img: nft_6, tier: '6', title: 'Jade Heart'},
		// 'test': {img: nft_7, tier: '7', title: 'Jade Heart'},
  }

  useEffect(() => {
    document.getElementById('wrapper').style.backgroundColor = '#000000'
    window.scrollTo({ top: 0, behavior: 'smooth' });
    console.log(slug)
    console.log(nftCards[slug])
    setNFT(nftCards[slug])
  }, [slug])
  
  return (
    <div className="container pt-5 mt-5">
      <div className="item_details mt-5">
        <div className="col-12 mb-5">
          <Link to="/nft-drops" className="btn btn-primary w-auto"><i className="ri-arrow-left-s-line"></i>Back</Link>
        </div>
        <div className="row sm:space-y-20">
          <div className="col-12 col-md-6 text-center">
            <img className={`item_img ${slug==='zenith-5' ? 'w-auto' : '' }`} src={nft?.img} alt=""></img>
          </div>
          <div className="col-12 col-md-6">
            <div className="space-y-20">
              <ItemDetailHeader
                cardName={nft?.title}
                // collectionSize={1}
                owner={"inheritance Art"} //data.owner
              />
              <div className="box bg-dark">
                <div className="space-y-20">
                  <div className="d-flex-column d-md-flex mb-3 justify-content-between mb-30_reset">
                    <TabsNavList>
                      <TabListItem classNames="btn btn-white btn-sm disabled" id="#tabs-1" ariaSelected >
                        Details
                      </TabListItem>
                        {/*<TabListItem classNames="btn btn-white btn-sm" id="#tabs-2" >*/}
                        {/*  Minting Power*/}
                        {/*</TabListItem>*/}
                        {/*<TabListItem classNames="btn btn-white btn-sm" id="#tabs-3" >*/}
                        {/*  Properties*/}
                        {/*</TabListItem>*/}
                    </TabsNavList>
                  </div>
                  <div className="hr"></div>
                  <TabsContent>
                    <TabPane active id="tabs-1">
                      {nft?.description}
                    </TabPane>
                      {/*<TabPane id="tabs-2">*/}
                      {/*  {mintingPower}*/}
                      {/*</TabPane>*/}
                      {/*<TabPane id="tabs-3">*/}
                      {/*  {properties} */}
                      {/*</TabPane>*/}
                  </TabsContent>
                </div>
              </div>
              {/* <ItemsDetailNumbers
                priceTitle="Public Sale Price"
                price={1000}
                currency={"iAI"}
                tierTitle="Tier"
                tier={1}
                contractAddress={'0x123...abc'}
                tokenID={123}
                isAdmin={true}
              /> */}
              {/* <div className="hr2"></div> */}

              <div className="creators pt-3 mb-5" style={{borderTop: "2px solid #2ae3fd"}}>
                <div className="row d-flex justify-content-center justify-content-md-between mb-3">
                  <div className="col-12 col-md-4 border-top-1">
                  {
                    nft?.tier && 
                    <>
                    <h6 className="color_text text-nowrap mr-2">Tier</h6>
                    <p className="d-flex">{nft?.tier}</p>
                    </>
                  }
                  </div>
                  {/*<div className="col-12 col-md-4 border-top-1">*/}
                  {/*  <h6 className="color_text text-nowrap mr-2">Token ID</h6>*/}
                  {/*  <p className="d-flex"> {tokenID} <CopyBtn copyText={tokenID}/> </p>*/}
                  {/*</div>*/}
                  <div className="col-12 col-md-4">
                    <h6 className="color_text text-nowrap mr-2">Contract Address</h6> 
                    <div className="d-flex"><p className="text-right" style={{width: 'max-content', margin: 0}}>{ slug.includes('zenith') ? '0x50e...3B0' : '0xc71...8C8'}</p><CopyBtn copyText={ slug.includes('zenith') ? "0x50ea1a726B509dD5fB5DaabD75C97FE76201A3B0" : "0xc71311619dCB498336B01d00CA6f433a664028C8"}/></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NFTDropDetail;
