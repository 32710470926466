import React from "react";
import { Link } from "react-router-dom";
import {useSystem} from "../../hooks/use-system";

function ArtworkCard({ image, title, userName, classNames }) {
  const system = useSystem();
  return (
    <div className={`${classNames ? classNames : 'col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-3'}`}>
      <div className="card card__item four bg-dark">
        <div className="card_body space-y-10">
          <div className="creators space-x-10">
            <div className="avatars space-x-3 w-100">
                <img src={ "https://dapps.blacklemon.wtf/assets/img/logos/iai_logo_black.jpeg" } alt="Avatar" className="avatar avatar-sm mr-2" />
              <Link to=""><p className="text-light">@{userName}</p></Link>
            </div>
          </div>
          <div className="card_head">
            <img src={image} alt="item img"/>
          </div>
            <h6 className="card_title">{title}</h6>
        </div>
      </div>
    </div>
  );
}

export default ArtworkCard;
