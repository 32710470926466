import React from 'react'
import Instagram from '../atoms/icons/ig'
import Twitter from '../atoms/icons/twitter'
import Discord from '../atoms/icons/discord'
import SectionHeader from './sectionHeader'

function CommunityLinks({titleBackdrop}) {
	return (
		<div className="container">
				<div className="community">
					<div className="section__head space-y-20">
						{/* <h3 className="section__title text-center">iAI Global Community</h3> */}
						<SectionHeader text="iAI Global Community" backdrop={titleBackdrop && "iAI GLOBAL COMMUNITY" } justify="center"/>
						<p className="section__text text-center"> 
							Learn more about our NFT Auction House and artist curation process!
						</p>
					</div>
			
					<div className="community__items">
						<div className="row justify-content-center mb-20_reset">
							<div className="col-md-3">
								<a href="https://twitter.com/InheritanceArt" target="_blank" rel="noreferrer">
									<div className="item space-y-10">
										<div className="logo">
											<Twitter/>
										</div>
										<p className="text-center">inheritance Art</p>
										<h5 className="text-center">Twitter</h5>
									</div>
								</a>	
							</div>
							<div className="col-md-3">
								<a href="https://discord.com/invite/inheritanceart" target="_blank" rel="noreferrer">
									<div className="item space-y-10">
										<div className="logo is_twitter">
											<Discord/>
										</div>
										<p className="text-center">inheritance Art</p>
										<h5 className="text-center">Discord</h5>

									</div>
								</a>
							</div>
							<div className="col-md-3">
								<a href="https://www.instagram.com/inheritanceart_official/" target="_blank" rel="noreferrer">
									<div className="item space-y-10">
										<div className="logo is_discord">
											<Instagram/>
										</div>
										<p className="text-center">inheritance Art</p>
										<h5 className="text-center">Instagram</h5>
									</div>
								</a>
							</div>
			
						</div>
					</div>
				</div>
			</div>
	)
}

export default CommunityLinks