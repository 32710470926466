import React, {useEffect, useState} from 'react'
import Button from '../../atoms/button'
import Input from '../../atoms/input'
import Modal from '../../atoms/modal'
import InputRow from '../../molecules/input-row'
import {GetNFTSeller, IsCollectionApproved, IsTokenApproved, UseContractMethod} from "../../../hooks/use-dapp";
import {useContract} from "../../../hooks/use-contract";
import {useEthers} from "@usedapp/core";
import web3 from "web3";
import config from "../../../config";
import {useInterface} from "../../../hooks/use-interface";
import {Contract} from "@ethersproject/contracts";
import addresses from "../../../config/addresses";
import callAPI from "../../../api";

function PutOnAuctionModal({collection, tokenId, ownerWallet}) {

    const {account} = useEthers();

    const contract = useContract();

    const intrf = useInterface();

    const [listingData, setData] = useState({
        address: collection.address,
        tokenId: tokenId,
        iniAI: false,
        isExclusive: false,
        whitelist: [],
        owner: ownerWallet
    });

    const {
        state, send, events
    } = UseContractMethod(contract?.auctionHouse, "listNFT");

    const curInterface = collection.type == config.constants.CONTRACT_TYPE.ERC721 ? intrf.erc721 : intrf.erc1155;
    const collectionContract = new Contract(collection.address, curInterface);

    const collectionApproved = IsCollectionApproved(collectionContract, account);
    const tokenApproved = IsTokenApproved(collectionContract, tokenId);

    const seller = GetNFTSeller(collectionContract, tokenId);

    const canList = collectionApproved || tokenApproved;

    const [isListed, setIsListed] = useState(false);
    const [isListing, setIsListing] = useState(false);
    const [listingMessage, setListingMessage] = useState(null);
    const [startedApproval, startApproval] = useState(false);
    const [approveBtn, setApproveBtn] = useState('Approve');

    const {
        state: stateApproval, send: sendApproval, events: eventsApproval
    } = UseContractMethod(collectionContract, "setApprovalForAll");

    const setListingDataAttr = (attr, value) => {
        listingData[attr] = value
        setData(listingData);
    }

    const listNFT = () => {
        send(listingData.address, listingData.tokenId, 1, listingData.seller,
            account, listingData.startingPrice, listingData.iniAI,
            listingData.duration, listingData.isExclusive, listingData.whitelist);
        setIsListing(true);
        setListingMessage('Please sign the transaction...');
    }

    const createListedNFT = () => {
        callAPI(listingData, "createListedNFT").then((resp) => {
            if (resp.status == 'SUCCESS') {
                setIsListed(true);
            }
        }).catch((err) => {
            alert(err);
        })
    }

    useEffect(() => {
            setListingDataAttr('seller', seller);
    }, [seller])

    useEffect(() => {


        if (state.status === 'Exception') {
            setIsListing(false);
        } else if (state.status === 'Mining') {
            setListingMessage('🔐 Transaction signed! Listing the NFT...')
        } else if (state.status === 'Success') {
            setListingMessage('NFT Listed for Auction!! Syncing with backend...');
            console.log(state.receipt)
            const listingId = state.receipt.events[state.receipt.events.length - 1].args.listingID.toNumber();
            setListingDataAttr('listing_id', listingId);
            setListingDataAttr('trx_hash', state.transaction.hash)
            createListedNFT();
        }
    }, [state, events]);

    useEffect(() => {
        if (stateApproval) {
            if (stateApproval.status === 'Exception') {

            } else if (stateApproval.status === 'Mining') {
                startApproval(true);
                setApproveBtn('Giving approval...')
            }
        }

    }, [stateApproval, eventsApproval]);

    return (
        <Modal title="Put on auction" classNames="bg-dark auction-modal" containerClassNames="staking-modal">

            {isListed ? (
                <>
                    <p>Your NFT has been listed for Auction and synced with backend successfully!</p>
                </>
            ) : (
                <>
                    <InputRow title={`${collection.name} #${tokenId}`}></InputRow>
                    <InputRow title="Bid in iAI" hasSwitch={true} switchOnChange={(e) => {
                        setListingDataAttr('iniAI', !listingData.iniAI)
                    }} isSwitched={listingData.iniAI}></InputRow>
                    <InputRow title="Exclusive (Only for Stakers)" hasSwitch={true} switchOnChange={(e) => {
                        setListingDataAttr('isExclusive', !listingData.isExclusive)
                    }} isSwitched={listingData.isExclusive}>
                        <InputRow></InputRow>
                        <InputRow title="Starting Price*" required>
                            <Input type="number" placeholder={`e.g. 5.0 ${listingData.iniAI ? 'iAI' : 'WETH'}`}
                                   onChange={(e) => {
                                       const weiPrice = web3.utils.toWei(e.target.value);
                                       setListingDataAttr('startingPrice', weiPrice)
                                   }}/>
                        </InputRow>
                        <InputRow title="Duration in days" required>
                            <Input type="number" placeholder="e.g. 5 days" onChange={(e) => {
                                setListingDataAttr('duration', e.target.value * 24 * 3600)
                            }}/>
                        </InputRow>
                    </InputRow>
                    <InputRow>
                        Whitelist (1 wallet per
                        line): <small>{listingData?.whitelist ? listingData.whitelist.length : 0} wallets
                        detected</small>
                        <textarea className={"form-control"} onChange={(e) => {
                            const extractedList = e.target.value.replace(/\r\n/g, "\n").split("\n");
                            let sanitizedList = [];
                            extractedList.forEach((w) => {
                                if (w.toLowerCase().indexOf('0x') == 0) {
                                    sanitizedList.push(w.toLowerCase());
                                }
                            })
                            setListingDataAttr('whitelist', sanitizedList);
                        }}></textarea>
                    </InputRow>

                    <div className="d-flex justify-content-center mt-5">
                        {isListing ? (
                            <p>{listingMessage}</p>
                        ) : (
                            <>
                                {!canList && collection.owner === account &&
                                    <Button disabled={startedApproval} classNames="btn btn-primary btn-sm mr-3"
                                            onClick={() => {
                                                sendApproval(addresses.auctionHouse, true);
                                            }}>{approveBtn}</Button>}
                                <Button disabled={!canList} classNames="btn btn-primary btn-sm mr-3"
                                        onClick={listNFT}>List</Button>
                                <Button classNames="btn btn-outline-primary btn-sm">Cancel</Button>
                            </>
                        )}
                    </div>
                </>
            )}

        </Modal>
    )
}

export default PutOnAuctionModal