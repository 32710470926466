import React, {useEffect, useState} from 'react'
import Button from '../../atoms/button'
import Input from '../../atoms/input'
import Modal from '../../atoms/modal'
import InputRow from '../../molecules/input-row'
import {UseContractMethod} from "../../../hooks/use-dapp";
import {useContract} from "../../../hooks/use-contract";
import callAPI from "../../../api";
import web3 from "web3";
import addresses from "../../../config/addresses";
import Countdown from "../../atoms/countdown";
import {prettyNumber} from "../../../utils/string";

function UnStakeIAIModal({accountStakedAmount, canUnstake, unstakeDate}) {

    const [approved, setApproved] = useState(false);
    const [staked, setStaked] = useState(false);
    const [isStaking, setIsStaking] = useState(false);
    const [unStakeAmount, setUnStakeAmount] = useState(accountStakedAmount);
    const [stakeMessage, setStakeMessage] = useState("");
    const contract = useContract();

    const {
        state: stateApproval,
        send: sendApproval,
        events: eventsApproval
    } = UseContractMethod(contract?.iai, "approve");

    const {
        state,
        send,
        events
    } = UseContractMethod(contract?.auctionHouse, "unstake");

    useEffect(() => {
        if (state.status === 'Exception') {
            setIsStaking(false);
        } else if (state.status === 'Mining') {
            setStakeMessage('🔐 Transaction signed! Unstaking your iAI...')
        } else if (state.status === 'Success') {
            setStaked(true);
        }
    }, [state, events]);

    // useEffect(() => {
    //     if (!approved) {
    //         if (stateApproval.status === 'Exception') {
    //             setIsStaking(false);
    //         } else if (stateApproval.status === 'Mining') {
    //             setStakeMessage('🔐 Transaction signed! Giving approval...');
    //         } else if (stateApproval.status === 'Success') {
    //             setApproved(true);
    //             setStakeMessage('Approval given, Please sign the stake transaction...');
    //             send(web3.utils.toWei(unStakeAmount));
    //         }
    //     }
    //
    // }, [stateApproval, eventsApproval]);

    const unStake = () => {
        // sendApproval(addresses.auctionHouse, web3.utils.toWei(unStakeAmount));
        send(web3.utils.toWei(unStakeAmount));
        setIsStaking(true);
        setStakeMessage("Please sign the transaction...")
    }

    return (
        <Modal title="Unstake iAI" classNames="bg-dark" containerClassNames="staking-modal">
            {!canUnstake ? (
                <p className={'text-center'}>You can unstake your {prettyNumber(unStakeAmount)} iAI in <Countdown date={unstakeDate}/> </p>
            ) : (


                staked ? (
                    <p>You have unstaked successfully your iAI!</p>
                ) : (
                    <>
                        <InputRow description={`Your staked amount is ${accountStakedAmount} iAI`} required>
                            <div className="max-input">
                                <input className="form-control" type={'number'} placeholder="Enter value"
                                       max={accountStakedAmount}
                                       value={unStakeAmount}
                                       onChange={(e) => {
                                           let newValue = parseFloat(e.target.value) <= parseFloat(accountStakedAmount) ? e.target.value : accountStakedAmount;
                                           setUnStakeAmount(newValue);
                                       }}/>
                                <button onClick={(e) => {
                                    setUnStakeAmount(accountStakedAmount);
                                }}>Max
                                </button>
                            </div>
                        </InputRow>
                        <div className="d-flex justify-content-center">
                            {isStaking ? (
                                <p>{stakeMessage}</p>
                            ) : (
                                <Button classNames="btn btn-primary btn-lg w-100" onClick={unStake}>Unstake</Button>
                            )}

                        </div>
                    </>
                )

            )}


        </Modal>
    )
}

export default UnStakeIAIModal