import React from 'react'

function Discord() {
  return (
	<svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M20.317 1.51641C18.7873 0.814531 17.147 0.297416 15.4319 0.00124241C15.4007 -0.00447365 15.3695 0.00981109 15.3534 0.0383812C15.1424 0.413596 14.9087 0.903095 14.7451 1.28784C12.9004 1.01167 11.0652 1.01167 9.25832 1.28784C9.09465 0.894543 8.85248 0.413596 8.64057 0.0383812C8.62449 0.0107643 8.59328 -0.00352041 8.56205 0.00124241C6.84791 0.296469 5.20756 0.813585 3.67693 1.51641C3.66368 1.52213 3.65233 1.53166 3.64479 1.54403C0.533392 6.19239 -0.31895 10.7265 0.0991801 15.2044C0.101072 15.2263 0.11337 15.2473 0.130398 15.2606C2.18321 16.7681 4.17171 17.6833 6.12328 18.2899C6.15451 18.2995 6.18761 18.2881 6.20748 18.2623C6.66913 17.6319 7.08064 16.9672 7.43348 16.2681C7.4543 16.2272 7.43442 16.1786 7.39186 16.1624C6.73913 15.9148 6.1176 15.6129 5.51973 15.2701C5.47244 15.2425 5.46865 15.1748 5.51216 15.1425C5.63797 15.0482 5.76382 14.9501 5.88396 14.8511C5.90569 14.833 5.93598 14.8291 5.96153 14.8406C9.88928 16.6338 14.1415 16.6338 18.023 14.8406C18.0485 14.8282 18.0788 14.832 18.1015 14.8501C18.2216 14.9491 18.3475 15.0482 18.4742 15.1425C18.5177 15.1748 18.5149 15.2425 18.4676 15.2701C17.8697 15.6196 17.2482 15.9148 16.5945 16.1615C16.552 16.1777 16.533 16.2272 16.5538 16.2681C16.9143 16.9662 17.3258 17.6309 17.7789 18.2614C17.7978 18.2881 17.8319 18.2995 17.8631 18.2899C19.8241 17.6833 21.8126 16.7681 23.8654 15.2606C23.8834 15.2473 23.8948 15.2272 23.8967 15.2053C24.3971 10.0284 23.0585 5.53148 20.3482 1.54498C20.3416 1.53166 20.3303 1.52213 20.317 1.51641ZM8.02002 12.4778C6.8375 12.4778 5.86313 11.3922 5.86313 10.0589C5.86313 8.72561 6.8186 7.63996 8.02002 7.63996C9.23087 7.63996 10.1958 8.73514 10.1769 10.0589C10.1769 11.3922 9.22141 12.4778 8.02002 12.4778ZM15.9947 12.4778C14.8123 12.4778 13.8379 11.3922 13.8379 10.0589C13.8379 8.72561 14.7933 7.63996 15.9947 7.63996C17.2056 7.63996 18.1705 8.73514 18.1516 10.0589C18.1516 11.3922 17.2056 12.4778 15.9947 12.4778Z" fill="white"/>
	</svg>
  )
}

export default Discord
