import Button from "../atoms/button"
// import heroImg from '../../assets/img/iai_nft.png'
import heroImg from '../../assets/img/bg/slide_1.jpg'
import { Link } from "react-router-dom"
import ArrowRight from "../atoms/icons/ArrowRight"

export default function HeroLG({nextRef}){	
	return(
			<div className="hero__1 bg-dark mb-5 mt-5" style={{background: `url(${heroImg})`, height: '85vh'}}>
					<div className="mx-auto" style={{maxWidth: '80vw'}}>
						<div className="row align-items-center">
								<div className="hero__left space-y-20" style={{position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>
									<h1 className="hero__title text-light" style={{fontSize: '48px'}}>
										Bringing your NFT Art <br></br>to a new dimension
									</h1>
									<p className="hero__text txt text-light" style={{fontSize: '25px', maxWidth: '80vw'}}>
										Your hyper realistic NFT holograms <br></br> by inheritance Art on a Sony Spatial<br></br> Reality Display
									</p>
									<div className="d-flex flex-column flex-md-row">
										<Button classNames="btn btn-primary mb-3 px-4 heroBtn" onClick={()=>{nextRef.current.scrollIntoView()}}>View Pricing &nbsp; <ArrowRight/></Button>
									</div>
								</div>
						</div>
			
					</div>
			</div>
	)
}