import React from 'react'
import EligibilityCard from '../molecules/eligibilityCard'
import SocialCard from '../molecules/social-card'

function ProfileSidebar({status, user}) {

	return (
		<div className="profile__sidebar">
			<div className="space-y-40">
				<div className="space-y-10">
					<h5>About me</h5>
					<EligibilityCard bio={user?.bio} status={status} assetsCount={3} />
				</div>
				<div className="space-y-10">
					<h5>Follow me</h5>
					<SocialCard twitterHandle={user?.twitter} discordHandle={user?.discord} instagramHandle={user?.instagram}/>
				</div>
			</div>

		</div>
	)
}

export default ProfileSidebar
