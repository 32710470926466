import React from 'react'
import Accordion from '../atoms/Accordion'
import AccordionItem from '../atoms/AccordionItem'

function FaqAccordion() {
  return (
						<Accordion classNames="faq-accordion">
							<AccordionItem  title="What are the PC requirements for a Spatial Reality Display?" id="faq_1" active={true}>
								<p>According to Sony, the computer required has a recommended CPU of Intel Core i7-9700K @3.60 GHz or faster; and a graphics card such as NVIDIA GeForce RTX 2070 SUPER or faster. Only Windows 10 (64-bit) is supported. </p>	
							</AccordionItem>
							<AccordionItem  title="Where can I buy the Sony Spatial Reality Display from?" id="faq_2">
								<p>You can purchase your Spatial Reality Display directly from <a href="https://electronics.sony.com/more/spatial-reality-display/p/elfsr1" className="text-primary" target={'_blank'}>Sony’s webshop</a>.</p>
							</AccordionItem>
							<AccordionItem  title="How much is one NFT Hologram?" id="faq_3">
								<p>The inheritance Spatial Spawns subscription in $iAI utility tokens equivalent to 5,000 USDC per year, will get you one (1) NFT spawned into Spatial Reality. Any additional spawning will cost 3,500 USDC per NFT -- by far the best price on the market! </p>
								<p>For non-subscribers, each NFT spawned into Spatial Reality is at 8,500 USDC per NFT.</p>
							</AccordionItem>
							<AccordionItem  title="If I only have one NFT and don't want the yearly subscription, can I still spawn my NFT via Spatial Reality?" id="faq_4">
								<p>Definitely. You can still get your NFTs spawned into Spatial Reality at a cost of 8,500 USDC per NFT.</p>
							</AccordionItem>
							<AccordionItem  title="How do I access the hologram of my NFT created by inheritance Art?" id="faq_5">
								<p>Once finalized and ready, the created Spatial Reality files are made available for you to download for the duration of one (1) year. <b>Please ensure a safe and reliable back-up of your Spatial Reality file</b>. You can download your Spatial Reality files, load them into the Spatial Reality software on your PC, and flaunt your hologram NFT anywhere you like!</p>
							</AccordionItem>
							
						</Accordion>
  )
}

export default FaqAccordion