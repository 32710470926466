import React, { useRef, useState } from 'react'
import avatarImg from '../../assets/img/avatars/avatar_1.png'
import placeholder from '../../assets/img/team/placeholder.png'
import Button from '../atoms/button'

function EditProfileHeader({dp, handleDelete}) {

	const imgRef = useRef();
	const [showDelete, setShowDelete] = useState(false);
	const handleImageChange = (e)=>{
		var reader = new FileReader();
		reader.onload = function (e) {
			imgRef.current.src = e.target.result;
			setShowDelete(true)
		}
		reader.readAsDataURL(document.querySelector('[name=dp_img]').files[0]);
	}
	const handleDeleteImg = ()=>{
		imgRef.current.src = placeholder;
		setShowDelete(false)
		handleDelete();
	}
	return (
		<div className="avatars space-x-20 mb-30">
			<div id="profile-container">
				<img id="profileImage" src={dp ? dp : placeholder} alt="" className="avatar avatar-lg border-0" ref={imgRef}/>
			</div>
			<div className="space-x-10 d-flex flex-column flex-md-row">
				<div id="boxUpload">
					<button className="btn btn-dark mb-3 mb-md-0"> Upload New Photo </button>
					<input id="imageUpload" type="file" name="dp_img" placeholder="Photo" required="" capture="" onChange={handleImageChange}/>
				</div>
				{(dp || showDelete) && <Button className="btn btn-white" onClick={handleDeleteImg}>Delete</Button>}
			</div>
		</div>
	)
}

export default EditProfileHeader
