import React from 'react'

function TabPane({id, active, children}) {
	return (
		<div className={`tab-pane ${active ? 'active' : '' }`} id={id} role="tabpanel">
			{children}
		</div>
	)
}

export default TabPane
