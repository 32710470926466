import React from 'react'
import SwitchToggle from '../atoms/switch'


function InputRow({title, description, required, hasSwitch, children, switchOnChange, isSwitched}) {
    return (
        <div className="form-group row">
            <div className="d-flex justify-content-between">
                {title && <span className="nameInput" dangerouslySetInnerHTML={{__html: title}}></span>}
                {
                    hasSwitch &&
                    <SwitchToggle label=""
                                  onChange={switchOnChange}
                                  isChecked={isSwitched}
                    />
                }
            </div>
            {description && <p className="text-muted mb-3">{description}</p>}
            {children}
        </div>
    )
}

export default InputRow