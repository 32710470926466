import Wallet from "../molecules/wallet";
import React, { useState } from "react";
import WalletMenu from "../molecules/wallet-menu";
import logoText from "../../assets/img/logos/iai_logo_text.png";
import logo from "../../assets/img/logos/iai_logo.png";
import { Link, NavLink } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useSystem } from "../../hooks/use-system";
import { useEthers } from "@usedapp/core";

export default function Header() {
  const [visible, setVisible] = useState(false);
  const system = useSystem();
  const { deactivate, account } = useEthers();
  const toggleVisible = () => {
    setVisible(!visible);
  };

  const renderMobileWallet = () => {
    return (
      // <div className="d-block d-md-none" style={{ margin: 'auto', marginLeft: 0 }}>
      <div className="d-block d-md-none" style={{ margin: "auto", marginLeft: 0, marginRight: 0 }}>
        {account ? (
          <WalletMenu account={account} deactivate={deactivate} />
        ) : (
          <a className="btn btn-grad btn-sm connect-wallet" onClick={() => system.showModal("choose-wallet")}>
            <i className="ri-wallet-3-line"></i> Connect wallet
          </a>
        )}
      </div>
    );
  };

  return (
    <header className="header__1 js-header" id="header">
      <div className="container">
        <div className="wrapper js-header-wrapper">
          <div className="header__logo">
            <a href="/">
              <img className="header__logo" id="logo_js" src={isMobile ? logo : logoText} alt="logo"></img>
            </a>
          </div>

          <div className="header__menu">
            <ul className="d-flex space-x-20">
              <li>
                <NavLink
                  style={({ isActive }) => {
                    return { color: isActive ? "#2ae3fd" : "#ffffff" };
                  }}
                  to="/"
                >
                  Home
                </NavLink>
              </li>
              <li>
                <a href="https://dapps.inheritance-art.com/nft-staking.html" target="_blank">
                  NFT Staking
                </a>
              </li>
              <li>
                <a href="https://mint.inheritance-art.com/" target="_blank">
                  9022 Mint
                </a>
              </li>
              {/*<li><NavLink className={({isActive})=>{return isActive ? 'active' : '' }} to="/nft-drops">NFT Drops</NavLink></li>*/}
              <li>
                <NavLink
                  className={({ isActive }) => {
                    return isActive ? "active" : "";
                  }}
                  to="/auction-house"
                >
                  Auction
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) => {
                    return isActive ? "active" : "";
                  }}
                  to="/sss"
                >
                  Spatial Spawns
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) => {
                    return isActive ? "active" : "";
                  }}
                  to="/news"
                >
                  News
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) => {
                    return isActive ? "active" : "";
                  }}
                  to="/about"
                >
                  About
                </NavLink>
              </li>
            </ul>
          </div>
          {renderMobileWallet()}
          {!isMobile && <Wallet account={account} deactivate={deactivate} />}
          <div className="header__burger js-header-burger" onClick={toggleVisible}></div>

          <div className={`header__mobile js-header-mobile ${visible ? "visible" : ""}`}>
            <div className="header__mobile__menu space-y-40">
              <ul className="d-flex space-y-20">
                <li className="text-right">
                  <NavLink to="/" onClick={() => setVisible(false)}>
                    Home
                  </NavLink>
                </li>
                <li className="text-right">
                  <a href="https://dapps.inheritance-art.com/nft-staking.html" target="_blank" onClick={() => setVisible(false)}>
                    NFT Staking
                  </a>
                </li>
                <li className="text-right">
                  <a href="https://mint.inheritance-art.com/" target="_blank">
                    9022 Mint
                  </a>
                </li>
                <li className="text-right">
                  <NavLink to="/auction-house" onClick={() => setVisible(false)}>
                    Auction
                  </NavLink>
                </li>
                <li className="text-right">
                  <NavLink to="/sss" onClick={() => setVisible(false)}>
                    Spatial Spawns
                  </NavLink>
                </li>
                <li className="text-right">
                  <NavLink to="/news" onClick={() => setVisible(false)}>
                    News
                  </NavLink>
                </li>
                {/*<li className="text-right"><NavLink to="/nft-drops" onClick={()=>setVisible(false)}>NFT Drops</NavLink></li>*/}
                <li className="text-right">
                  <NavLink to="/about" onClick={() => setVisible(false)}>
                    About
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
