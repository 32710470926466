import { useState } from "react";

export default function CopyBtn({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
	<div style={{position: 'relative'}} className="copy-btn">
		<span className="bg-success rounded p-1 text-light" style={{display: isCopied ? 'block': 'none', position: 'absolute', left: '40px', fontSize: '12px', width: '60px'}}>Copied !</span>
		<i className="ri-file-copy-line ml-2 border rounded-circle p-1 icon-small hover" onClick={handleCopyClick}></i>
	</div>
  );
}