const stage =
    process.env.REACT_APP_ENV === 'production'
        ? 'MAINNET' : (
            process.env.REACT_APP_ENV === 'staging'
                ? 'RINKEBY' : process.env.REACT_APP_ENV.toUpperCase()
        );
export default {
    usdc: process.env[`REACT_APP_USDC_ADDRESS_${stage}`],
    iai: process.env[`REACT_APP_IAI_ADDRESS_${stage}`],
    weth: process.env[`REACT_APP_WETH_ADDRESS_${stage}`],
    erc721: process.env[`REACT_APP_AH_ERC721_ADDRESS_${stage}`],
    erc1155: process.env[`REACT_APP_AH_ERC1155_ADDRESS_${stage}`],
    auctionHouse: process.env[`REACT_APP_AH_ADDRESS_${stage}`]
};