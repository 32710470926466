import React, {useState} from 'react'
import callAPI from "../../api";
import {useEthers} from "@usedapp/core";

function SubmittedNFT({nft, onUpdate}) {
    const {account} = useEthers();

    const [link, setLink] = useState('');
    const [disabled, setDisable] = useState(false);
    const [message, setMessage] = useState('');

    const submitNFT = () => {
        if (link != '') {
            setDisable(true);
            setMessage('')
            callAPI({id: nft.public_id, nft_link: link, wallet: account}, 'updateNFT').then((data) => {
                if (data.status === 'SUCCESS') {
                    onUpdate(data.payload.subscriber)
                } else {
                    setDisable(false);
                }
            })
        } else {
            setMessage('Please enter a value in the link field')
        }

    }

    const nftDate = new Date(nft.created_dt);
    const nftHoloDate = nft.holo_link_dt ? new Date(nft.holo_link_dt) : '';

    var btnStyle = {
        'backgroundColor': '#2AE3FD',
        'border': 'solid 1px #2AE3FD',
        'borderRadius': '10px',
        'padding': '5px 15px'
    }

    return (
        <div className={'mb-3 col-md-6'}>
            <div className="submittedNFT px-3 py-4" style={{
                borderRadius: '20px',
                background: '#000',
                minHeight: 250,
                display: 'flex',
                flexFlow: 'column'
            }}>

                {nft.nft_link ? (
                        <>
                            <div className={'my-2'}><b>Submitted NFT</b>: <a href={nft.nft_link}>{nft.nft_link}</a></div>
                            <div className={'my-2'}><b>Submission Date</b>: {nftDate.toLocaleString()}</div>
                        </>
                    )
                    : (
                        <>
                            <div><b>Submitted NFT</b>: N/A</div>
                            <div className={'mb-2'}>
                                <input className={'py-2 my-2'} type={'text'} name={'link'} value={link}
                                       placeholder={'Enter your new nft link'} onChange={(e) => {
                                    setLink(e.target.value);
                                }}/>
                                <div><small style={{color: 'red'}}><i>{message}</i></small></div>
                                <button onClick={submitNFT} style={btnStyle} disabled={disabled}>Submit</button>
                            </div>
                        </>
                    )}

                <div className={'my-2'}><b>Status</b>: {nft.status}</div>
                {nft.holo_link && (
                    <div className={'my-2'}><b>Hologram Link</b>: <a href={nft.holo_link}>{nft.holo_link}</a></div>)}
                {nft.holo_link_dt && (
                    <div className={'my-2'}><b>Hologram Finished Date</b>: {nftHoloDate.toLocaleString()}</div>)}
                {nft.iai_note && (<div className={'my-2'}><b>Note</b>: <i>{nft.iai_note}</i></div>)}
            </div>
        </div>
    )
}

export default SubmittedNFT