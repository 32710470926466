import React, {useEffect, useState} from 'react'
import Button from '../atoms/button'
import Dropdown from '../atoms/dropdown'
import PageTitle from '../atoms/pageTitle'
import AuctionCard from '../molecules/AuctionCard'
import SectionHeader from '../molecules/sectionHeader'
import auctionHouseBanner from '../../assets/img/banners/auction_house.jpg'
import auctionHouseBannerMobile from '../../assets/img/banners/mobile/auction_house.jpg'

import PageBanner from '../molecules/page-banner'
import callAPI from "../../api";
import Switch from "../atoms/switch";
import {isMobile} from 'react-device-detect'
import CollectionCategory from "../atoms/CollectionCategory";

function AuctionHouse() {

    const [nfts, setNFTs] = useState([]);
    const [sortLabel, setSortLabel] = useState('Newest')
    const [qF, setQF] = useState({
        on_bid: true,
        is_exclusive: false,
        category: 'ALL',
        sort_by: '-created_dt',
    });

    const [categories, setCategories] = useState([]);

    const setQFAttr = (attr, value) => {
        qF[attr] = value;
        setQF(qF);
        getListedNFTs();
    }

    useEffect(() => {
        if (nfts.length == 0)
            getListedNFTs()

        if (categories.length == 0)
            getCategories()
    }, [])


    const getCategories = () => {

        callAPI({}, 'getCategories').then((data) => {
            const fetchedCategories = data.payload.categories;
            const allCat = [{
                name: 'All',
                slug: 'ALL'
            }]

            setCategories(allCat.concat(fetchedCategories))
        });

    }

    const getListedNFTs = () => {
        callAPI(qF, 'getListedNFTs').then((data) => {
            setNFTs(data.payload.nfts)
        });
    }

    const categoryButtons = [
        {text: 'All', icon: 'ri-function-line', category: 'all'},
        {text: 'Category 1', icon: 'ri-gamepad-line', category: 'all'},
        {text: 'Category 2', icon: 'ri-flashlight-line', category: 'all'},
        {text: 'Category 3', icon: 'ri-quill-pen-line', category: 'all'},
        {text: 'Category 4', icon: 'ri-headphone-line', category: 'all'},
        {text: 'Category 5', icon: 'ri-global-line', category: 'all'},
    ]

    const sortByDropdown = [
        {
            text: 'Newest', value: '-created_dt', onClick: (e) => {
                setQFAttr('sort_by', '-created_dt');
                setSortLabel('Newest');

            }
        },
        {
            text: 'Ending Soon', value: 'end_dt', onClick: (e) => {
                setQFAttr('sort_by', 'end_dt');
                setSortLabel('Ending Soon');
            }

        }
    ]

    return (
        <>
            <PageBanner img={isMobile ? auctionHouseBannerMobile : auctionHouseBanner}/>
            <PageTitle text1="inheritance Art" text2="NFT AUCTION HOUSE"/>
            <div className="container">
                <div className="row d-flex justify-content-center py-3">
                    {categories.map(c =>
                        <Button classNames={'btn btn-sm border-0 mx-2 bg-dark category-btn'} onClick={() => {
                            setQFAttr('category', c.slug)
                        }}>
                            <CollectionCategory category={c.name} active={c.slug === qF.category}/>
                        </Button>
                    )}
                    {/*{categoryButtons.map(btn =>*/}
                    {/*    <Button classNames={'btn btn-sm border-0 mx-2 bg-dark'} empty><i*/}
                    {/*        className={`${btn.icon} mr-1`}></i>{btn.text}</Button>*/}
                    {/*)}*/}
                </div>
            </div>
            <div className="container">
                <SectionHeader text="Auctions"/>
                <div className="d-flex flex-column flex-md-row justify-content-between py-5">
                    <div className="d-flex flex-column flex-md-row mb-3 mb-md-0">
                        <span className="mr-5 mb-3 mb-md-0">FILTER BY</span>
                        <div className="d-flex flex-column flex-md-row">
                            <Switch
                                onChange={(checked) => {
                                    setQFAttr('on_bid', checked)
                                }}
                                label='Open for bidding'
                                options={{
                                    color: '#474F79',
                                    size: 'small'
                                }}
                                isChecked={qF.on_bid}
                            />
                            <Switch

                                onChange={(checked) => {
                                    setQFAttr('is_exclusive', checked)
                                }}
                                label='Exclusive (iAI Staking Required)'
                                options={{
                                    color: '#474F79',
                                    size: 'small'
                                }}
                                isChecked={qF.is_exclusive}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-start justify-content-md-end">
                        <span className="mr-2 mb-3 mb-md-0">SORT BY</span>
                        <Dropdown items={sortByDropdown} text={sortLabel}/>
                    </div>
                </div>
                <div className="d-flex row">
                    {nfts.map(
                        nft => {
                            return (
                                <AuctionCard nft={nft} key={nft.listing.list_id}/>
                            )
                        }
                    )}

                    {nfts.length == 0 && <p>No auction listings matches your search criteria!</p>}
                </div>
                {/*<div className="d-flex justify-content-center mt-4">*/}
                {/*    <span className="mr-3">1</span>*/}
                {/*    <span className="mr-3">2</span>*/}
                {/*    <span className="mr-3">3</span>*/}
                {/*    <span className="text-primary"><i className='ri-arrow-right-s-line'></i></span>*/}
                {/*</div>*/}
            </div>
        </>
    )
}

export default AuctionHouse