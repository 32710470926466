import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import callAPI from '../../api'
import Button from '../atoms/button'
import NewsCard from '../molecules/NewsCard'

function LatestNews() {

	const [articles, setArticles] = useState([]);


	useEffect(() => {
		callAPI({page:1, page_length:3}, 'getArticlesList').then((data)=>{
			if(data.status==='SUCCESS'){
				setArticles([...articles, ...data.payload.articles]);
			}else if(data.message==='NO_ARTICLES_FOUND'){
			}
		})
	}, []);
	return (
		<>
			<div className="row d-flex justify-content-center">
			{articles.map(article=><NewsCard id={1} key={Math.random()} article={article} classNames="col-12 col-md-4"/>)}	
			</div>
			<div className="d-flex flex justify-content-center">
			<Link to="/news"><Button classNames="btn btn-primary">View All News</Button></Link>
			</div>
		</>
	)
}

export default LatestNews