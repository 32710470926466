import React from 'react'

function EligibilityCard({status, assetsCount, bio}) {
	return (
		<div className="box space-y-20 bg-dark">
			{bio ? <p>{bio}</p> : <p>No user bio set! Edit <br></br>profile to update bio.</p>}
			{/* <div className="row">
				<div className="col-6">
					<span className="txt_sm color_text">Launchpad</span>
					<h5 className={`text-${status ? 'success' : 'danger'}`}>{status ? 'Elligible' : 'Not Elligible' }</h5>
				</div>
				<div className="col-6">
					<span className="txt_sm color_text">Assets</span>
					<h4>{assetsCount}</h4>
				</div>
			</div> */}
		</div>
	)
}

export default EligibilityCard
