import config from "../config";

const baseURL = process.env.REACT_APP_API_BASE_URL

const actions = {
    getUserStatus: data => ({
        method: 'get',
        url: 'user/status'
    }),
    getAHSettings: data => ({
        method: 'get',
        url: 'general/ah_settings'
    }),
    getSettings: data => ({
        method: 'get',
        url: 'general/settings'
    }),

    validateAndCreate: data => ({
        method: 'post',
        url: 'subscription/validate-and-create'
    }),
    activateDevice: data => ({
        method: 'post',
        url: 'auth/activate-device'
    }),
    updateNFT: data => ({
        method: 'post',
        url: 'subscription/update-nft'
    }),
    submitContact: data => ({
        method: 'get',
        url: 'general/submit-contact'
    }),
    getUserProfile: data => ({
        method: 'get',
        url: 'user/get-user-profile'
    }),
    updateProfile: data => ({
        method: 'post',
        url: 'user/update-profile'
    }),
    getArticlesList: data => ({
        method: 'get',
        url: 'articles/get-articles/'
    }),
    getSingleArticle: data => ({
        method: 'get',
        url: 'articles/get-single-article/'
    }),
    getListedNFTs: data => ({
        method: 'get',
        url: 'ah/nfts/list'
    }),

    createNFT: data => ({
        method: 'post',
        url: 'ah/nfts/create',
        hasUpload: true
    }),

    createListedNFT: data => ({
        method: 'post',
        url: 'ah/nfts/listing/create'
    }),

    cancelListing: data => ({
        method: 'post',
        url: 'ah/nfts/listing/cancel'
    }),

    getNFT: data => ({
        method: 'get',
        url: 'ah/nfts/get'
    }),

    nftHistory: data => ({
        method: 'get',
        url: 'ah/nfts/history'
    }),

    userBidHistory: data => ({
        method: 'get',
        url: 'ah/bids/history'
    }),

    getCategories: data => ({
        method: 'get',
        url: 'ah/categories/list'
    }),

    getCollections: data => ({
        method: 'get',
        url: 'ah/collections/list'
    }),

    createCollection: data => ({
        method: 'post',
        url: 'ah/collections/create'
    }),
    createdNFTs: data => ({
        method: 'get',
        url: 'ah/nfts/created'
    }),
    assets: data => ({
        method: 'get',
        url: 'ah/nfts/assets'
    }),
    placeBid: data => ({
        method: 'post',
        url: 'ah/bids/place'
    }) ,
    setClaimed: data => ({
        method: 'post',
        url: 'ah/nfts/listing/claim'
    })
}

const callAPI =
    (data, type) => {
        var myHeaders = new Headers();

        myHeaders.append("auth-token", localStorage.getItem(config.constants.STORAGE.AUTH_TOKEN));
        myHeaders.append("device-id", localStorage.getItem(config.constants.STORAGE.DEVICE_ID));
        let dt = actions[type](data);
        let body;

        if (dt.hasUpload) {

            const formData = new FormData();
            Object.keys(data).forEach((k) => {
                formData.append(k, data[k])
            })

            body = formData

        } else {
            myHeaders.append("Content-Type", "application/json");
            body = JSON.stringify(data);
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: body,
            redirect: 'follow'
        };

        return fetch(baseURL + dt.url, requestOptions)
            .then(resp => resp.json())
            .then(function (resp) {
                if(resp.message === 'INVALID_AUTH_TOKEN'){
                    localStorage.removeItem(config.constants.STORAGE.AUTH_TOKEN);
                    window.location.reload();
                }else{
                    return resp;
                }
            })
            .catch(function (error) {
                throw new Error(error);
            });


    };

export default callAPI;