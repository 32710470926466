import * as React from 'react';

function SvgDone(props) {
  return (
    <svg
      width={100}
      height={50}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className=""
      {...props}>
      <circle cx={25} cy={25} r={25} fill="#2AE3FD" />
      <path
        d="M19.133 22.63c-.589-.547-1.424-.4-1.866.33-.442.728-.323 1.762.266 2.308L23.71 31l8.81-9.083c.566-.583.642-1.623.17-2.323-.47-.7-1.311-.795-1.877-.212l-7.19 7.413-4.49-4.166z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgDone;
