import React from 'react'
import {Link} from 'react-router-dom'
import logo from '../../assets/img/logos/iai_logo.png'
import Button from '../atoms/button'
import {GetListingInfo} from "../../hooks/use-dapp";
import CollectionCategory from "../atoms/CollectionCategory";
import CountdownTimer from "../atoms/countdown";
import {prettyNumber} from "../../utils/string";

function AuctionCard({nft, classNames}) {

    const likes = nft.likes;
    const nftLink = `/auction-house/collection/${nft.collection.address}/${nft.token_id}`
    const listingInfo = GetListingInfo(nft.listing.list_id);

    const bidAlready = listingInfo != {} && listingInfo.highestBidder !== null;

    const auctionEnded = listingInfo && listingInfo.endTime && listingInfo.endTime*1000 <= Date.now();

    return (
        <div classNames={classNames ? classNames : ''}>
            <div className="card auction-card mb-3">
                <Link to={nftLink}>
                    <div className="card-body row">
                        <div className="card__item one mb-0">
                            <div className="card_head">
                                <a href={nftLink}>
                                    <img src={nft.img} alt="..."/>
                                </a>
                                {/*<Button href="#" classNames="likes space-x-3">*/}
                                {/*    <i className="ri-heart-3-fill"></i>*/}
                                {/*    <span className="txt_sm">{likes}</span>*/}
                                {/*</Button>*/}
                                <Button classNames="btn btn-dark btn-sm category-tag" href="#">
                                    <CollectionCategory category={nft?.collection?.category}/>
                                </Button>
                                {
                                    Object.keys(listingInfo).length > 0 && (
                                        <div className="details space-x-0 d-flex justify-content-between flex-column flex-md-row">
                                            <div className="bid space-x-10">
                                                <div className="w-100">
                                                    <p className="color_text txt text-center">{auctionEnded ? 'Sold At' : bidAlready ? 'CURRENT BID' : 'MINIMUM BID'}</p>
                                                    <p className="txt_lg _bold text-center">{prettyNumber(listingInfo.price)} <small>{listingInfo.in_iAI ? 'iAI' : 'WETH'}</small></p>
                                                </div>
                                            </div>

                                            <div className="auction_end">

                                                <p className="color_text txt text-center"> AUCTION END</p>
                                                {bidAlready ? (
                                                    <CountdownTimer date={new Date(listingInfo.endTime * 1000)}/>
                                                    // <div
                                                    //     className="d-flex justify-content-center align-items-center space-x-10 txt_lg _bold">
                                                    //     <div className="item">
                                                    //         <div className="number hours">10<span></span></div>
                                                    //     </div>
                                                    //     <div className="dots">:</div>
                                                    //     <div className="item">
                                                    //         <div className="number minutes">44<span></span></div>
                                                    //     </div>
                                                    //     <div className="dots">:</div>
                                                    //     <div className="item">
                                                    //         <div className="number seconds">24<span></span></div>
                                                    //     </div>
                                                    // </div>
                                                ) : (
                                                    <small><i>Will start once <br/>1st bid is placed</i></small>
                                                )
                                                }
                                            </div>


                                            <div className="avatar rounded-circle">
                                                <img src={logo}/>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                        <div className="col ml-0 ml-md-4 pt-3">
                            <div className="d-flex mb-3">
                                <h3>{nft.title}</h3>
                            </div>
                            <div className="d-flex mb-3">
                                <p className='lead'>{nft.description}</p>
                            </div>
                            <div className="d-flex mb-3">
                                <p>{nft.details}</p>
                            </div>
                            <div className="d-flex">
                                <span>{auctionEnded ? 'Sold At' :bidAlready ? 'Current Bid': 'Minimum Bid'}: {prettyNumber(listingInfo.price)} {listingInfo?.in_iAI ? 'iAI' : 'WETH'}</span>
                            </div>
                            <div className="d-flex mb-3">
                                <span>Learn More</span>
                            </div>
                            {auctionEnded ? (
                                null
                            ) : (
                                <div className="d-flex">
                                    <button className="btn btn-primary w-auto px-5">Place a Bid</button>
                                </div>
                            )}

                        </div>

                    </div>
                </Link>
            </div>
        </div>
    )
}

export default AuctionCard