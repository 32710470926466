import { ChainId, Rinkeby, Mainnet } from '@usedapp/core';

const stage = process.env.REACT_APP_ENV;

console.log(`Network: ${stage === 'staging' ? 'RINKEBY' : 'MAINNET'}`);

var readUrlsDict = {};
if(stage === 'staging'){
    readUrlsDict[ChainId.Rinkeby] = `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`;
}else{
    readUrlsDict[ChainId.Mainnet] = `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`;
}

const DappConfig = {
    readOnlyChainId: stage === 'staging' ? ChainId.Rinkeby : ChainId.Mainnet,
    readOnlyUrls: readUrlsDict,
    pollingInterval: 1000,
    networks: [stage === 'staging' ? Rinkeby : Mainnet],
    notifications: {
      checkInterval: 100,
      expirationPeriod: 10000,
    },
    autoConnect: true
}

export default DappConfig;