export default function CollectionCategory({category, active}) {

    // const categoryButtons = [
    //     {text: 'All', icon: 'ri-function-line', category: 'all'},
    //     {text: 'Category 1', icon: 'ri-gamepad-line', category: 'all'},
    //     {text: 'Category 2', icon: 'ri-flashlight-line', category: 'all'},
    //     {text: 'Category 3', icon: 'ri-quill-pen-line', category: 'all'},
    //     {text: 'Music', icon: 'ri-headphone-line', category: 'all'},
    //     {text: 'Category 5', icon: 'ri-global-line', category: 'all'},
    // ]
    const categoryDict = {
        'All': {
          icon: 'ri-function-line'
        },
        'Classical': {
            icon: 'ri-music-2-line'
        },
        'Painting': {
            icon: 'ri-quill-pen-line'
        },
        'Contemporary': {
            icon: 'ri-flashlight-line'
        },
        'Pop Culture': {
            icon: 'ri-headphone-line'
        },
        'Abstract': {
            icon: 'ri-focus-2-fill'
        }

    }

    const getIcon = () => {

        const catObj = categoryDict[category];

        return catObj?.icon;
    }

    return (
        <>
            <i className={getIcon()}></i> <span className={active? 'active': null}>{category}</span>
        </>
    );
}
