import React from 'react';
import {useSystem} from '../../hooks/use-system';

export default function Message({ children, type }) {
  const { setMessage } = useSystem();

  return (
    <div className={`system-message ${type==='error'? 'error' : 'success'}`}
      onClick={() => setMessage()}>
      {children}
    </div>
  );
}
