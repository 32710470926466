import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone';


export default function Dropzone({onFileLoad}) {
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                // console.log(binaryStr);
                // console.log(reader);
                onFileLoad(file);
            }
            reader.readAsArrayBuffer(file);

        })

    }, [])
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop});

    const files = acceptedFiles.map(file => (
        <li key={file.name}>
            {file.name} - {file.size} bytes
        </li>
    ));

    return (
        <div {...getRootProps()}>
            <div className="left__part   upload_file my-5">
                <div className="d-flex justify-content-center flex-column mx-auto mb-5">
                    {files && files.length > 0 ? (
                        <aside>
                            <ul>{files}</ul>
                        </aside>
                    ): (
                        <>
                            <img className="icon" src="/assets/img/icons/upload.svg" alt=""/>
                            <h5 className="mx-auto">Drag and drop your file</h5>
                            <p className="color_text">PNG, GIF, WEBP, MP4 or MP3. Max 100mb.</p>
                        </>
                    )}
                </div>
                <div className="d-flex justify-content-center flex-column">
                    <span className="color_text mx-auto mb-3">or choose a file</span>
                    <a href="#" className="btn btn-white mx-auto"> choose file </a>
                    <input type="file" {...getInputProps()} />
                </div>
            </div>
        </div>
    )
}