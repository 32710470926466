import React, { useState } from 'react'
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

function ShareBtn() {
	const renderShareBtns = ()=>{
		if (typeof window !== "undefined") {
			return(
				<>
				<li><FacebookShareButton url={window.location.href}><i className="ri-facebook-line"></i></FacebookShareButton></li>
				<li><TelegramShareButton url={window.location.href}><i className="ri-telegram-line"></i></TelegramShareButton></li>
				<li><TwitterShareButton url={window.location.href}><i className="ri-twitter-line"></i></TwitterShareButton></li>
				<li><WhatsappShareButton url={window.location.href}><i className="ri-whatsapp-line"></i></WhatsappShareButton></li>
				</>
			)
		}
	}
	const [visible, setVisible] = useState(false)
	return (
		<div className="share">
			<div className="icon bg-light text-dark" onClick={()=>{setVisible(!visible)}}>
				<a href="#"><i className="ri-share-line text-dark"></i></a>
			</div>
			<div className={`dropdown__popup ${visible?'visible':''}`}>
				<ul className="space-y-10">
					{renderShareBtns()}
				</ul>
			</div>
		</div>
	)
}

export default ShareBtn
