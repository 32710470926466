import axios from "axios";
import config from "../config";

const baseURL = process.env.REACT_APP_API_BASE_URL;//"https://dapps-apis.inheritance-art.com/api/"
// const baseURL = "localhost:8000/api/"
// const baseURL = "http://0180-78-182-185-168.ngrok.io/api/"

export const endpoints = {
    SINGLE_ARTICLE: 'articles/get-single-article',
    ARTICLES_LIST: 'articles/get-articles',
    UPDATE_PROFILE: 'user/update-profile',
    FETCH_PROFILE: 'user/get-user-profile',
}

export const API = axios.create({
    baseURL: baseURL, headers: {
        "auth-token": localStorage.getItem(config.constants.STORAGE.AUTH_TOKEN),
        "device-id": localStorage.getItem(config.constants.STORAGE.DEVICE_ID)
    }
});