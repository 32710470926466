import {WalletConnectConnector} from '@web3-react/walletconnect-connector';

import DappConfig from './dapp.js';
import {InjectedConnector} from "@web3-react/injected-connector";
import config from "./index";

const POLLING_INTERVAL = DappConfig.pollingInterval;
const RPC_URLS = DappConfig.readOnlyUrls[DappConfig.readOnlyChainId];

export const injected = new InjectedConnector();


const walletconnect = new WalletConnectConnector({
    rpc: {[DappConfig.readOnlyChainId]: RPC_URLS},
    bridge: 'https://bridge.walletconnect.org',
    infuraId: process.env.REACT_APP_INFURA_KEY,
    qrcode: true,
    pollingInterval: POLLING_INTERVAL,
    supportedChainIds: [DappConfig.readOnlyChainId],
    chainId: DappConfig.readOnlyChainId,
});


export default {
    injected,
    walletconnect,
}