import React from 'react'
import Button from '../../atoms/button'
import Input from '../../atoms/input'
import Modal from '../../atoms/modal'
import InputRow from '../../molecules/input-row'

function BurnNFTModal() {
  return (
    <Modal title="Burn NFT" classNames="bg-dark" containerClassNames="staking-modal">
		<p className="text-muted">Are you sure you want to burn this NFT?</p>
		<div className="d-flex justify-content-center">
			<Button classNames="btn btn-danger btn-sm mr-3">Burn</Button>
			<Button classNames="btn btn-primary btn-sm">Cancel</Button>
		</div>
		
    </Modal>
  )
}

export default BurnNFTModal