import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/img/logos/iai_logo.png'

function Footer() {
  return (
	  <footer className="footer__1">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 space-y-20">
							<div className="footer__logo">
								<a href="/">
									<img src={logo} alt="logo" id="logo_js_f" style={{width: "50px", height: "50px"}}/>
								</a>
							</div>
							<div>
								<ul className="footer__social space-x-10 mb-40">
									<li> <a href="https://twitter.com/InheritanceArt"> <i className="ri-twitter-line"></i> </a>
									</li>
									<li> <a href="https://discord.com/invite/inheritanceart"> <i className="ri-discord-line"></i> </a>
									</li>
									<li> <a href="https://www.instagram.com/inheritanceart_official/"> <i className="ri-instagram-line"></i> </a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-2 col-6">
							<ul className="footer__list">
								<li> <Link to="/"> Home </Link> </li>
								<li> <Link to="/auction-house">Auction </Link></li>
								<li> <Link to="/profile"> Profile </Link> </li>
								{/* <li> <Link to="/admin-profile">Admin Profile</Link> </li> */}
								<li> <Link to="/news"> News </Link> </li>
								<li> <Link to="/about"> About </Link> </li>
								{/* <li> <Link to="/token-integration"> iAI Token Integration </Link> </li> */}
							</ul>
						</div>
						<div className="col-lg-2 col-6">
							<ul className="footer__list">
								<li> <Link to="privacy"> Privacy Policy </Link>
								</li>
								<li> <Link to="terms"> Terms & Conditions </Link>
								</li>
								<li> <Link to="support"> Support </Link>
								</li>
							</ul>
						</div>
					</div>
					<p className="copyright text-center">
						Copyright © 2022 inheritance Art <br></br> Powered by: <a href="https://www.blacklemon.wtf/" style={{fontWeight: 'bold', textDecoration: 'underline !important'}}>BLACK LEMON</a>
					</p>
				</div>
			</footer>
  )
}

export default Footer