import React from "react";

function Button({ classNames, children, loading, disabled, empty, onClick }) {
  return (
    <button
      disabled={disabled}
      className={`${classNames ? classNames : 'btn btn-primary'} ${loading ? "disabled" : ""}`}
      type="button"
      onClick={disabled?null:onClick}
    >
      {loading && <i className="ri-donut-chart-fill md spin mr-2"></i>}
      {children}
    </button>
  );
}

export default Button;
