import React, { useEffect, useState } from "react";
import TabListItem from "../atoms/tab-list-item";
import TabsNavList from "../atoms/tabsNavList";
import TabPane from "../atoms/tabPane";
import TabsContent from "../atoms/tabsContent";
import ItemDetailHeader from "../atoms/item-detail-header";
import ItemsDetailNumbers from "../atoms/items-detail-numbers";
// import CountdownTimer from "../atoms/countdown";
import Button from "../atoms/button";
import BidListItem from "../molecules/bidListItem";
import HistoryListItem from "../molecules/historyListItem";
import {useSystem} from "../../hooks/use-system";
import ItemDetailFooter from "../atoms/item-detail-footer";
import fineArt from '../../assets/img/items/fine_art.jpg'

function AdminItemDetail({ contract, data, showInfo, showBuyNow, showSell, isOwner, isAuthorized, isCanPutOnSale }) {

  const system = useSystem();	
  const description = 'Lorem ipsum dolor sit amet...'
  useEffect(() => {
    document.getElementById('wrapper').style.backgroundColor = '#141414'
  
  }, [])
  
  return (
    <div className="container">
      <div className="item_details mt-5">
        <div className="row sm:space-y-20">
          <div className="col-12 col-md-6">
            <img className="item_img" src={fineArt} alt=""></img>
          </div>
          <div className="col-12 col-md-6">
            <div className="space-y-20">
              <ItemDetailHeader
                cardName={"Fine Art NFT"}
                collectionSize={1}
                owner={"Collection 1"} //data.owner
              />
              <div className="box bg-dark">
                <div className="space-y-20">
                  <div className="d-flex-column d-md-flex mb-3 justify-content-between mb-30_reset">
                    <TabsNavList>
                      <TabListItem
                        classNames="btn btn-white btn-sm active"
                        id="#tabs-1"
                        ariaSelected
                      >
                        Details
                      </TabListItem>
                        <TabListItem
                          classNames="btn btn-white btn-sm"
                          id="#tabs-2"
                        >
                          Bids
                        </TabListItem>
                        <TabListItem
                          classNames="btn btn-white btn-sm"
                          id="#tabs-3"
                        >
                          History
                        </TabListItem>
                    </TabsNavList>

                      {/* <div className="auction_end d-flex">
                        <p className="color_text txt my-auto mr-2">Countdown</p>
                        <CountdownTimer date={data?.sale.expirationTime * 1000} />
                      </div> */}

                  </div>
                  <div className="hr"></div>
                  <TabsContent>
                    <TabPane active id="tabs-1">
                      {description}
                    </TabPane>
                      <TabPane id="tabs-2">
                        <BidListItem isAdmin={true} actionText="Heighest Bid" value="5000 iAI" style={{borderBottom:'1px solid #ffffff', borderBottomLeftRadius:'0', borderBottomRightRadius:'0', paddingBottom: '10px', marginBottom: '10px'}}/>
                        <BidListItem isAdmin={true} actionText="Bid Accepted" value="5000 iAI"/>
                      </TabPane>
                      <TabPane id="tabs-3">
                        <HistoryListItem actionText="Purchased by" value="5000 iAI"/>
                        <HistoryListItem actionText="Transfered to" value="5000 iAI"/>
                        <HistoryListItem actionText="Minted by" value="5000 iAI"/>
                      </TabPane>
                  </TabsContent>
                </div>
              </div>
              <ItemsDetailNumbers
                priceTitle="Public Sale Price"
                price={1000}
                currency={"iAI"}
                tierTitle="Tier"
                tier={1}
                contractAddress={'0x123...abc'}
                tokenID={123}
                isAdmin={true}
              />
              {/* <div className="hr2"></div> */}
              <ItemDetailFooter />
              <div className="d-flex space-x-20">
                    <Button
                      href=""
                      classNames="btn btn-primary btn-lg"
                      data-toggle="modal"
                      data-target="#popup_buy"
                      onClick={()=>system.showModal('place-bid', {})}
                    >
                      Place a Bid
                    </Button>
                    <Button
                      href=""
                      classNames="btn btn-primary btn-lg"
                      data-toggle="modal"
                      data-target="#popup_buy"
                      onClick={()=>system.showModal('place-bid', {})}
                    >
                      Cancel
                    </Button>
                    <Button
                      href=""
                      classNames="btn btn-primary btn-lg"
                      data-toggle="modal"
                      data-target="#popup_buy"
                      onClick={()=>system.showModal('place-bid', {})}
                    >
                      Burn
                    </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminItemDetail;
