import {useSystem} from '../../hooks/use-system';

function Modal({id, title, children, classNames, containerClassNames}) {


	const system = useSystem();

	const closeModal = () => {
		system.setProcessStages([]);

		// if (system.modal.name === "buy" || system.modal.name === "sell") {
		// 	system.showModal("dontRefresh", {});
		// 	return;
		// }
		// if (system.modal.name === "process-modal") {
		// 	system.setProcessStages([]);
		// }
		system.showModal("", {});
	};

	const handleClickOverlay = (e) => {
		if (!e.target.closest('.modal')) {
			closeModal();
		}
	};

	return (
		<div className="modal fade popup show"  id={id} tabIndex="-1" aria-hidden="true" onClick={handleClickOverlay}>
			<div className={`modal-dialog modal-dialog-centered ${containerClassNames}`} role="document">
				<div className={`modal-content ${classNames}`}>
					<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
						<span aria-hidden="true">×</span>
					</button>
					<div className="modal-body space-y-20 p-40">
						{title && <h3>{title}</h3>}
						{children}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Modal