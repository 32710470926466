import addresses from '../config/addresses';

import {Contract} from "@ethersproject/contracts";

import {utils} from "ethers";
import ERC20ABI from "../abis/erc20.json";
import ERC721ABI from "../abis/Inheritance721_ABI.json";
import ERC1155ABI from "../abis/Inheritance1155_ABI.json";
import AuctionHouseABI from "../abis/AuctionHouse_ABI.json";

export const useContract = () => {

    const erc20Interface = new utils.Interface(ERC20ABI);
    const iaiInterface = new utils.Interface(ERC20ABI);
    const erc721Interface = new utils.Interface(ERC721ABI);
    const erc1155Interface = new utils.Interface(ERC1155ABI);
    const auctionHouseInterface = new utils.Interface(AuctionHouseABI);

    return {
        weth: new Contract(addresses.weth, erc20Interface),
        usdc: new Contract(addresses.usdc, erc20Interface),
        iai: new Contract(addresses.iai, iaiInterface),
        erc721: new Contract(addresses.erc721, erc721Interface),
        erc1155: new Contract(addresses.erc1155, erc1155Interface),
        auctionHouse: new Contract(addresses.auctionHouse, auctionHouseInterface)
    };
};

export default {};