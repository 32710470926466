import React from 'react'
import nftIcon1 from '../../assets/img/icons/nft_icon1.png'
import nftIcon2 from '../../assets/img/icons/nft_icon2.png'
import nftIcon3 from '../../assets/img/icons/nft_icon3.png'
import nftIcon4 from '../../assets/img/icons/nft_icon4.png'
import nftIcon5 from '../../assets/img/icons/nft_icon5.png'
import nftIcon6 from '../../assets/img/icons/nft_icon6.png'
import nftIcon7 from '../../assets/img/icons/nft_icon7.png'
import nftIcon8 from '../../assets/img/icons/nft_icon8.png'
import iai_icon from '../../assets/img/icons/iai_icon.png'

function FindMore() {
  return (
    <div className="find-more mb-5" style={{position: 'relative'}}>
	    <h3 className="text-center mb-5">Find Out More</h3>
	    <p className="text-light text-center">We know our Service left you fascinated. Keen on knowing more? Feel free to ask any questions you may have in our<br></br><a href="https://discord.com/invite/inheritanceart" className="text-primary"><b>Discord server!</b></a></p>
	    <img src={nftIcon1} className="d-none d-md-bolck" style={{position: 'absolute', top:'0', left: '0'}}></img>
	    <img src={nftIcon2} className="d-none d-md-bolck" style={{position: 'absolute', top:'150px', left: '250px'}}></img>
	    <img src={nftIcon3} className="d-none d-md-bolck" style={{position: 'absolute', top:'300px', left: '100px'}}></img>
	    <img src={nftIcon4} className="d-none d-md-bolck" style={{position: 'absolute', top:'450px', left: '400px'}}></img>
	    <img src={nftIcon5} className="d-none d-md-bolck" style={{position: 'absolute', top:'0', right: '0'}}></img>
	    <img src={nftIcon6} className="d-none d-md-bolck" style={{position: 'absolute', top:'150px', right: '250px'}}></img>
	    <img src={nftIcon7} className="d-none d-md-bolck" style={{position: 'absolute', top:'300px', right: '100px'}}></img>
	    <img src={nftIcon8} className="d-none d-md-bolck" style={{position: 'absolute', top:'450px', right: '400px'}}></img>
	    <img src={iai_icon} className="d-none d-md-bolck" style={{position: 'absolute', bottom:'0', left: '50%', transform: 'translateX(-50%)'}}></img>
    </div>
  )
}

export default FindMore