import Header from "../organisms/header";
import Footer from "../molecules/footer";
import { Outlet, useLocation } from "react-router-dom";
import SystemModals from "../organisms/modals";
import { SystemProvider } from "../../hooks/use-system";

export default function Container() {
  const location = useLocation();
  return (
      <div className="overflow-hidden" id="wrapper" style={{backgroundColor: location.pathname === '/profile' || location.pathname === '/admin-profile' ? '#141414' : '#000000'}}>
        <Header />
          <SystemProvider>
            <SystemModals/>
            <Outlet/>
          </SystemProvider>
        <Footer />
      </div>
  );
}
