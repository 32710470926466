import React from 'react'

function TabListItem({id, ariaSelectd, classNames, itemClassNames, disabled, children}) {
	const handleClick = (e)=>{
		// console.log(id)
		document.querySelectorAll('.tab-pane')
			.forEach(el=>{ 
				el.classList.remove('active');
			 })
		document.querySelector(id).classList.add('active')
		document.querySelectorAll('.tab-btn').forEach(el=>el.classList.remove('active'))
		console.log(e.currentTarget.classList)
		e.currentTarget.children[0].classList.add('active')
	}
	return (
		<li className={`nav-item mr-2 mb-3 mb-md-0 ${disabled?'disabled':''} ${itemClassNames||''}`} onClick={e => handleClick(e)}>
			<a className={`tab-btn btn btn-primary ${disabled?'disabled':''} ${classNames}`} disable={disabled?"true":"false"} data-toggle="tab" role="tab" aria-selected={ariaSelectd ? 'true' : 'false'}>
				{children}
			</a>
		</li>
	)
}

export default TabListItem