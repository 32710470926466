import React from 'react'

function SocialCard({twitterHandle, instagramHandle, discordHandle}) {
	return (
		<div className="box bg-dark">
		{
			((twitterHandle || instagramHandle || discordHandle) &&
			<ul className="social_profile space-y-10 overflow-hidden">
				{
					twitterHandle && 
					<li className="mb-3">
						<a href={`https://www.twitter.com/${twitterHandle}`} target="_blank">
							<i className="ri-twitter-line"></i>
							<span className="color_text">twitter/</span>
							<span className="text-decoration-underline color_text">{twitterHandle}</span>
						</a>
					</li>
				}
				{
					instagramHandle && 
					<li className="mb-3">
						<a href={`https://instagram.com/${instagramHandle}`} target="_blank">
							<i className="ri-instagram-line"></i>
							<span className="color_text">instagram/</span>
							<span className="text-decoration-underline color_text">{instagramHandle}</span>
						</a>
					</li>
				}
				{
					discordHandle && 
					<li>
						<a href={`https://discordapp.com/users/${discordHandle}`} target="_blank">
							<i className="ri-discord-line"></i>
							<span className="color_text">discord/</span>
							<span className="text-decoration-underline color_text">{discordHandle}</span>
						</a>
					</li>
				}
			</ul>) || <p>No user social contact set!<br></br> Edit profile to update social contacts.</p>
		}
		</div>
	)
}

export default SocialCard
