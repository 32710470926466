import WalletMenu from './wallet-menu';
import {useSystem} from '../../hooks/use-system';


function Wallet({account, deactivate}) {

    const system = useSystem();
    return (
        <>
            {account ?
                <WalletMenu account={account} deactivate={deactivate}/>
                : (
                    <div className="header__btns">
                        <a className="btn btn-grad btn-sm mr-3" onClick={() => system.showModal('choose-wallet')}>
                            <i className="ri-wallet-3-line"></i> Connect wallet
                        </a>
                    </div>
                )}
        </>
    );
}

export default Wallet;
