import React from 'react'
import SectionHeader from '../molecules/sectionHeader'
import tokenomics from '../../assets/img/tokenomics.svg'
import road from '../../assets/img/bg/road.png'
import PageTitle from '../atoms/pageTitle'
import PageBanner from '../molecules/page-banner'
import RoadMap from '../organisms/roadmap'

function Tokenomics() {
  return (
	  <>
	<PageBanner/>
	<PageTitle text1={"Roadmap & Tokenomics"} classNames="bg-dark" />
	<div className="col-12 col-md-6 mx-auto text-center mb-5 px-3">
		<p>Through our social media presence, we loop in the entire NFT community, as well as everyone interested in <span className="text-primary">NFT world</span>, on the latest industry news from across the globe, in addition to center-ataging NFT talent <span className="text-primary">through our Weekly Discord</span> Creator AMA sessions.</p>
	</div>
    <div className='container'>
	    <SectionHeader text="Roadmap"/>
	    <div className="row mt-5 mb-5">
	    	<div className='col-12 col-md-7'>
			<RoadMap/>
		</div>
	    	<div className='col-12 col-md-5'>
			<img src={road} className="img-fluid my-5 py-5"/>
		</div>
	    </div>
	    <SectionHeader text="Tokenomics"/>
	    <div className="row mt-5">
		<div className="col-12 col-md-5">
			<div className="mb-3">
				<h3 className="mb-2">Total Supply</h3>
				<p>Total 1.000.000 (1 trilion)</p>
			</div>
			<div className="mb-3">
				<h3 className="mb-2">Tokenomics</h3>
				<p>inheritance Art’s vision is to grant everyone, everywhere the ability to enjoy experience and obtain fine </p>
			</div>
			<div className="mb-3">
				<h3 className="mb-2">Core Contributors</h3>
				<p>inheritance Art’s vision is to grant everyone, everywhere the ability to enjoy experience and obtain fine </p>
			</div>
			<div className="mb-3">
				<h3 className="mb-2">Advisors</h3>
				<p>inheritance Art’s vision is to grant everyone, everywhere the ability to enjoy experience and obtain fine </p>
			</div>
			<div className="mb-3">
				<h3 className="mb-2">Development</h3>
				<p>inheritance Art’s vision is to grant everyone, everywhere the ability to enjoy experience and obtain fine </p>
			</div>
			<div className="mb-3">
				<h3 className="mb-2">Marketing</h3>
				<p>inheritance Art’s vision is to grant everyone, everywhere the ability to enjoy experience and obtain fine </p>
			</div>
		</div>
		<div className="col-12 col-md-7 mb-5 mb-md-0">
			<img src={tokenomics} alt='tokenomics' className="w-100"/>
		</div>
	    </div>
    </div>
</>
  )
}

export default Tokenomics