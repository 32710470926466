import React, {useRef, useState} from 'react'
import SectionHeader from '../molecules/sectionHeader'
import HeroLG from '../organisms/hero-large'
import spatialDisplay from '../../assets/img/spatial_display.png'

import elipse_right from '../../assets/img/bg/elipse_right.png'
import elipse_left from '../../assets/img/bg/elipse_left.png'
import Button from '../atoms/button'
import {useSystem} from '../../hooks/use-system'
import ArrowRight from '../atoms/icons/ArrowRight'
import FaqAccordion from '../molecules/faqAccordion'
import RequirementsAccordion from '../molecules/RequirementsAccordion'
import FindMore from '../molecules/FindMore'
import callAPI from "../../api";
import {isMobile} from "react-device-detect";
import {Helmet} from "react-helmet";
import {useEthers} from "@usedapp/core";
import {BalanceOf} from "../../hooks/use-dapp";
import {useContract} from "../../hooks/use-contract";
import web3 from "web3";

function SSS() {
    const {account} = useEthers();
    const system = useSystem();
    const contract = useContract();

    const iaiBalance = web3.utils.fromWei(BalanceOf(contract?.iai, account));
    const usdcBalance =  web3.utils.fromWei(BalanceOf(contract?.usdc, account));

    const plansRef = useRef();

    const [settings, setSettings] = useState(null);
    const [subscriptions, setSubscriptions] = useState(null);
    const [subscriber, setSubscriber] = useState(null);
    const [canPay, enablePay] = useState(false);

    const payBtnClick = (payType) => {
        if (account && canPay) {
            const modalType = payType === 'oneTime' ? 'pay-sss' : 'subscribe-sss';
            system.showModal(modalType, settings[modalType]);
        } else {
            system.showModal('choose-wallet');
        }
    }

    React.useEffect(() => {

        if (window.location.hash) {
            var scollableElm = document.getElementById(window.location.hash.replace("#", ""));
            if (scollableElm) {
                scollableElm.scrollIntoView();
            }
        }

        if (subscriber === null && account) {
            callAPI({wallet: account}, "getUserStatus").then((data) => {
                if (data.payload && data.payload.subscriber)
                    setSubscriber(data.payload.subscriber)
                else
                    setSubscriber({'wallet': account})
            });
        }

    }, [subscriber, account])

    React.useEffect( () => {

        if(subscriptions){
            if (subscriptions.length === 2) {

                let oneTimePayAmount = subscriptions[0].amount
                let oneTimePayCurrency = subscriptions[0].currency

                if (subscriber && subscriber.subscription && subscriber.subscription.public_id === subscriptions[0].public_id) {
                    oneTimePayAmount = subscriptions[0].secondary_amount
                    oneTimePayCurrency = subscriptions[0].secondary_currency
                }

                const oneTimeCurrencyBalance = oneTimePayCurrency.toLocaleLowerCase() === 'iai' ? iaiBalance : usdcBalance;


                let recurringPayAmount = subscriptions[1].amount
                let recurringPayCurrency = subscriptions[1].currency;

                if (subscriber && subscriber.subscription && subscriber.subscription.public_id === subscriptions[1].public_id) {
                    recurringPayAmount = subscriptions[1].secondary_amount
                    recurringPayCurrency = subscriptions[1].secondary_currency
                }

                const recurringCurrencyBalance = oneTimePayCurrency.toLocaleLowerCase() === 'iai' ? iaiBalance : usdcBalance;

                setSettings({
                    'pay-sss': {
                        subscription: subscriptions[0].public_id,
                        subscriber: subscriber,
                        receiverWallet: subscriptions[0].receiver_wallet,
                        balance: oneTimeCurrencyBalance,
                        payAmount: oneTimePayAmount,
                        payCurrency: oneTimePayCurrency,
                        account: account
                    },
                    'subscribe-sss': {
                        subscription: subscriptions[1].public_id,
                        receiverWallet: subscriptions[1].receiver_wallet,
                        balance: recurringCurrencyBalance,
                        subscriber: subscriber,
                        payAmount: recurringPayAmount,
                        payCurrency: recurringPayCurrency,
                        account: account
                    }
                })
            } else {
                setSettings({})
            }
        }

    }, [iaiBalance, usdcBalance, subscriptions]);

    React.useEffect(() => {

        enablePay(true);

        if (!settings && usdcBalance >= 0 && iaiBalance >= 0) {
            callAPI({}, "getSettings").then((data) => {
                setSubscriptions(data.payload.subscriptions);
            }).catch((d) => console.error(d));
        }
    }, [iaiBalance, usdcBalance, canPay])


    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Spatial Spawns Service | Inheritance Art</title>
                <meta name="twitter:title" content="Spatial Spawns Service | Inheritance Art"/>
            </Helmet>
            {/* <Slider {...sliderSettings}> */}
            <HeroLG nextRef={plansRef}/>
            {/* <HeroLG /> */}
            {/* </Slider> */}

            <div className="my-5 py-2"></div>
            <div className="container section">
                <div className="d-flex mb-5 row">
                    <div className="col-12 mb-3" style={{position: 'relative'}}>
                        {/* <img src={elipse_right} className="d-none d-md-block" style={{position: 'absolute', right: '0'}}></img> */}
                        <SectionHeader text="Spatial Spawns Service"/>
                        <div className='col-12 col-md-7 mb-5'>
                            <p className="text-light" style={{fontSize: '25px', fontWeight: '100'}}>
                                Think about it this way: You’re the proud owner of some amazing NFTs. If there’s a way
                                to view your NFTs as <span className='text-primary'>hyper realistic holograms</span> and
                                display them absolutely anywhere, wouldn’t you go for it?
                            </p>
                        </div>
                        <p className="text-light section__title">The Spatial Spawns service by inheritance Art is making
                            it possible.</p>
                    </div>

                </div>
                <div className="d-flex mb-5 row">
                    <div className="col-12 col-md-7">
                        <RequirementsAccordion/>
                    </div>
                    <div className="col-12 col-md-5">
                        <img src={spatialDisplay} className="w-100"></img>
                    </div>
                </div>
                <div className="container py-5 mb-3">
                    <SectionHeader text="Spatial Reality Display"/>
                    <p className="lead text-light mb-3">With Sony’s Spatial Reality Display, you can enjoy glasses-free
                        steroscopic 3D viewing and images that react immediately to head movements. Enjoy the depth,
                        texture, and appearance of the object with a real sense of experiencing 3D reality with only
                        your naked eye!</p>
                    <div className='col-12 col-md-9 mx-auto youtube-embed'>
                        <iframe width="100%" height={isMobile ? 300 : 600}
                                src="https://www.youtube.com/embed/KrLMnQM0_Ps?controls=0"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                </div>
            </div>
            <div className="pt-5" style={{position: 'relative'}} id={"choosePlan"} ref={plansRef}>
                <img src={elipse_left} className="d-none d-md-block" style={{position: 'absolute', left: '0'}}></img>
                <img src={elipse_right} className="d-none d-md-block"
                     style={{position: 'absolute', right: '-35px', bottom: '50px'}}></img>
                <div className="container">

                    <h2 className="mb-5 text-center">Choose Your Plan</h2>
                    <div className="row d-flex justify-content-evenly">
                        <div className="col-12 col-md-4 mb-5 mb-md-0">
                            <div className="card plan-card secondary" style={{cursor: 'default'}}>
                                <h3 className="card-header secondary text-center p-5">ONE TIME FEE</h3>
                                <div className="card-body bg-dark">
                                    <p className="card-text text-light py-4">For NFT owners who want their NFT spawned
                                        into Spatial Reality without the yearly service subscription.</p>
                                    <hr style={{backgroundColor: '#177A88'}}></hr>
                                    <ul className="plan-list">
                                        <li className="mb-3">One of your favorite NFTs spawned into Spatial Reality</li>
                                        <li className="mb-3">Unique 8K resolution hyper realistic NFT Hologram</li>
                                        <li className="mb-3">NFT Hologram price <span className="text-primary">8,500 USDC</span>
                                        </li>
                                    </ul>
                                    <div className='d-flex justify-content-center'><Button
                                        disabled={!canPay}
                                        onClick={() => {
                                            payBtnClick('oneTime');
                                        }}>BUY NOW &nbsp; <ArrowRight/></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-5 mb-md-0">
                            <div className="card plan-card" style={{cursor: 'default'}}>
                                <h3 className="card-header text-center p-5">YEARLY SUBSCRIPTION</h3>
                                <div className="card-body gradient-bg">
                                    <p className="card-text text-light py-4">For NFT holders who want to get
                                        multiple
                                        NFT Holograms with all the benefits of the yearly Spatial Spawns
                                        subscription.</p>
                                    <hr style={{backgroundColor: '#177A88'}}></hr>
                                    <ul className="plan-list mb-5">
                                        <li className="mb-3">One of your favorite NFTs spawned into Spatial
                                            Reality
                                        </li>
                                        <li className="mb-3">Unique 8K resolution hyper realistic NFT Hologram</li>
                                        <li className="mb-3">NFT Hologram price in iAI Token equals <span
                                            className="text-primary">5,000 USDC</span></li>
                                        <li className="mb-3">For every extra NFT Hologram ordered, you get it at
                                            discounted price of <span className="text-primary">3,500 USDC </span>
                                        </li>
                                        <li className="mb-3">Priority access to all Inheritance Art projects</li>
                                    </ul>
                                    <div className='d-flex justify-content-center mb-5'><Button
                                        disabled={!canPay}
                                        onClick={() => {
                                            payBtnClick('subscription');
                                        }}>SUBSCRIBE NOW &nbsp; <ArrowRight/></Button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container py-5 mb-5">
                    <SectionHeader text="Your questions answered"/>
                    <FaqAccordion/>
                </div>
                <div className="container py-5 mb-5">
                    <FindMore/>
                </div>
            </div>
        </>
    )
}

export default SSS;