import React from 'react'
import avatarImg from '../../assets/img/avatars/avatar_1.png'
import Button from '../atoms/button'
import {prettyNumber, shorter} from "../../utils/string";
import config from "../../config";
import {Link} from "react-router-dom";

function BidListItem({b, listingInfo, showNFT, style}) {

    const historyDate = new Date(b.created_dt);
    const nftLink = `/auction-house/collection/${b.nft?.collection?.address}/${b.nft?.token_id}`

    return (
        <div className="creator_item creator_card space-x-10 bg-dark" style={style}>
            <div className="avatars space-x-10">
                <div className="media">
                    {/*<div className="badge">*/}
                    {/*	<img src='' alt=""/>*/}
                    {/*</div>*/}
                    {showNFT ? (
                        <Link to={nftLink}>
                            <img src={b.nft?.img} alt="Avatar" style={{width: 100}}/>
                        </Link>
                    ) : (

                        <a href="#">
                            <img src={b.user.image_url ? b.user.image_url : avatarImg} alt="Avatar"
                                 className="avatar avatar-md"/>
                        </a>
                    )}
                </div>
                <div>
                    <div className="d-flex justify-content-between">
                        <p className="color_white">
                            Bid
                            <span
                                className="color_brand mx-1">{prettyNumber(b.action_value)} {b.nft.listing.in_iAI ? 'iAI' : 'WETH'}</span> {!showNFT && <span>by</span>}

                            {!showNFT && <span
                                className="color_white txt_bold ml-2">{b.user.username ? b.user.username : shorter(b.user.wallet)}</span>}
                        </p>
                    </div>
                    <span
                        className="date color_text">{historyDate.toLocaleDateString()} {historyDate.toLocaleTimeString()}</span>
                </div>
                <a target={'_blank'} href={`https://${config.Ethscan}${b.trx_hash}`}><i
                    className="ri-external-link-line text-light ml-4 my-auto" style={{fontSize: '32px'}}></i></a>
            </div>
        </div>
    )
}

export default BidListItem