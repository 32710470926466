import React from 'react'
import avatarImg from '../../assets/img/avatars/avatar_1.png'
import badge from '../../assets/img/icons/Badge.svg'
import {shorter} from "../../utils/string";
import config from "../../config";

function HistoryListItem({h}) {

	const actionDict = {
		'LIST': 'Listed by',
		'CLAIM': 'Claimed by'
	}

	const historyDate = new Date(h.created_dt);

  return (
	  <div className="creator_item creator_card space-x-10 bg-dark">
		<div className="avatars space-x-10">
			<div className="media">
				{/*<div className="badge">*/}
				{/*	<img src={badge} alt=""/>*/}
				{/*</div>*/}
				<a href="#">
					<img src={h.user.image_url ? h.user.image_url : avatarImg} alt="Avatar" className="avatar avatar-md" />
				</a>
			</div>
			<div>
				<p className="color_white">
					{actionDict[h.action]}
					<span className="color_white txt_bold ml-2">{h.user.username ? h.user.username : shorter(h.user.wallet)}</span>
					<a target={'_blank'} href={`https://${config.Ethscan}${h.trx_hash}`}><i className="ri-external-link-line text-light ml-4 my-auto" style={{fontSize: '32px'}}></i></a>
				</p>
				<span className="date color_text">{historyDate.toLocaleDateString()} {historyDate.toLocaleTimeString()}</span>
			</div>
		</div>
	</div>
  )
}

export default HistoryListItem