import React from 'react'

function TabsNavList({children, classNames}) {
	return (
		<ul className={`nav nav-tabs d-flex mb-30 ${classNames||''}`} role="tablist">
			{children}
		</ul>
	)
}

export default TabsNavList