import React from 'react'
import roadmap_bg from '../../assets/img/bg/roadmap_bg.png'

function RoadMap() {
	const DateDiv = ({year, quarter, children})=>{
		return(
			<li>
				<div className="d-flex rounded flex-column flex-md-row">
					<div className="date d-flex flex-column p-5 rounded text-center" style={{backgroundImage: `url(${roadmap_bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
						<h1>{quarter}</h1>
						<h2>{year}</h2>
					</div>
					<div className="details p-3 rounded">
						<ul>
							{children}
						</ul>
					</div>
				</div>
			</li>
		)
	}
	return (
			<div className="mx-auto pr-5">
				<ul className="timeline">
				<DateDiv quarter="Q4" year="2021">
								<li>Inroduction of Seed NFT</li>
				</DateDiv>
				<DateDiv quarter="Q1" year="2022">
								<li>Public Sale</li>
								<li>Seed NFT Distribution</li>
								<li>inheritance NFT Staking</li>
				</DateDiv>
				<DateDiv quarter="Q2" year="2022">
					<li>Introduction to inheritance AI</li>
					<li>inheritance Team Expansion</li>
					<li>Exchange Listing</li>
					<li>inheritance AI Spatial Reality</li>
					<li>Release and more</li>
				</DateDiv>
				<DateDiv quarter="Q3" year="2022">
					<li>TBA</li>
				</DateDiv>
			</ul>	
			</div>
	)
}

export default RoadMap