import React from 'react'

function AboutCard({img, name, title, text, socials}) {
  return (
	<div className="col-12 col-md-4 mb-10">
		<div className="creator_item creator_card space-y-10 is_big h-100"  style={{position: 'relative'}}>
			<div className="avatars flex-column space-y-10 p-3">
				<div className="media">
					<img src={img} alt="Avatar" className="avatar avatar-md" />
				</div>
				<div className="text-center">
					<a href="Profile.html">
						<p className="avatars_name large text-light">{name}</p>
					</a>
					<span className="sales color_text">{title}</span>
				</div>
				<div className="text-left">
						<p className="text-light">{text}</p>
				</div>
				{
					socials &&
					<div className="socials d-flex justify-content-center">
						{socials.linkedIn && <a target={'_blank'} href={socials.linkedIn} className="mr-3"><i className="ri-linkedin-line ri-2x"></i></a>}
						{socials.twitter && <a target={'_blank'} href={socials.twitter} className="mr-3"><i className="ri-twitter-line ri-2x"></i></a>}
						{socials.instagram && <a target={'_blank'} href={socials.instagram}><i className="ri-instagram-line ri-2x"></i></a>}
					</div>
				}
			</div>
		</div>
	</div>
  )
}

export default AboutCard